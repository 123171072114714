/* eslint-disable @typescript-eslint/no-unused-vars */
import { IAPIResponse } from '../../models/common';
import { IQuote } from '../../models/quote';
import axiosInstance from '../axiosInstance';

export const PATHS = Object.freeze({
  COMPARE: `/api/v1/quote/comparison`,
  QUOTE_SAVE: `/api/v1/quote/comparison/save`,
});

const getQuotes = async (
  quoteIds: string[],
  opportunityId: string | null,
): Promise<IAPIResponse<IQuote[]>> => {
  const response = await axiosInstance.get(`${PATHS.COMPARE}`, {
    params: {
      ids: quoteIds.join(','),
      opportunityId,
    },
  });
  return response.data;
};

interface SaveQuotesByIds {
  quote_list: string[];
  comparison_data: any;
}

const saveQuotesByIds = async (data: SaveQuotesByIds): Promise<any> => {
  const response = await axiosInstance.post(PATHS.QUOTE_SAVE, data);
  return response.data;
};
const getQuotesByIds = async (ids: string): Promise<any> => {
  const response = await axiosInstance.get(`${PATHS.COMPARE}?quote_ids=${ids}`);
  return response.data;
};

export interface QuoteComparisonAPIClient {
  readonly getQuotes: (
    quoteIds: string[],
    opportunityId: string | null,
  ) => Promise<IAPIResponse<IQuote[]>>;
  readonly saveQuotesByIds: (data: SaveQuotesByIds) => Promise<any>;
  readonly getQuotesByIds: (ids: string) => Promise<any>;
}

const QuoteComparisonClient: QuoteComparisonAPIClient = Object.freeze({
  getQuotes,
  saveQuotesByIds,
  getQuotesByIds,
});

export default QuoteComparisonClient;
