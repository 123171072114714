import * as Yup from 'yup';
import { REGEX } from '../../utils/helperService';

const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const schema = Yup.object({
  name: Yup.string()
    .trim()
    .required('Please enter Account Name')
    .matches(
      REGEX,
      'Name should have more than 1 character, start with an alphabet and can have special characters like .,-_&',
    ),
  account_number: Yup.string().max(30, 'must be less than 30 characters').nullable(),
  contact_name: Yup.string()
    .trim()
    .max(30, 'must be less than 30 characters')
    .matches(
      REGEX,
      'Name should have more than 1 character, start with an alphabet and can have special characters like .,-_&',
    )
    .nullable(),
  email: Yup.string().trim().matches(EMAIL_REGEX, 'Please enter a valid email id').nullable(),
  description: Yup.string().max(80, 'must be less than 80 characters').nullable(),
  ownership: Yup.string().max(30, 'must be less than 30 characters').nullable(),
  upsell_opportunity: Yup.string().max(30, 'must be less than 30 characters').nullable(),
  type: Yup.string().max(30, 'must be less than 30 characters').nullable(),
  quote_to_name: Yup.string()
    .trim()
    .max(30, 'must be less than 30 characters')
    .matches(
      REGEX,
      'Name should have more than 1 character, start with an alphabet and can have special characters like .,-_&',
    )
    .nullable(),
  quote_to_address: Yup.string().max(30, 'must be less than 30 characters').nullable(),
  shipping_country: Yup.string().nullable(),
  shipping_city: Yup.string().nullable(),
  shipping_street: Yup.string().max(30, 'must be less than 30 characters').nullable(),
  shipping_state: Yup.string().nullable(),
  shipping_postal_code: Yup.string().max(10, 'must be less than 10 characters').nullable(),
  ship_to_name: Yup.string().nullable(),
  billing_country: Yup.string().nullable(),
  billing_city: Yup.string().nullable(),
  billing_street: Yup.string().max(30, 'must be less than 30 characters').nullable(),
  billing_state: Yup.string().nullable(),
  billing_postal_code: Yup.string().max(10, 'must be less than 10 characters').nullable(),
  bill_to_name: Yup.string().max(30, 'must be less than 30 characters').nullable(),
  owner_id: Yup.string().nullable(),
  site: Yup.string().max(30, 'must be less than 30 characters').nullable(),

  annual_revenue: Yup.number()
    .typeError('Annual revenue should be a valid number')
    .test('max-digits', 'Please enter correct value for annual revenue', (value) => {
      return !value || value.toString().length <= 18;
    })
    .nullable(),
  no_of_employees: Yup.number()
    .typeError('Please enter only integers greater than 0')
    .min(0, 'Number of employees must be greater than 0')
    .test('max-digits', 'Please enter correct number of employees ', (value) => {
      return !value || value.toString().length <= 18;
    })
    .nullable(),
  website: Yup.string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      'Enter correct url',
    )
    .nullable(),
  year_started: Yup.string().nullable(),
  funding_amount: Yup.number()
    .typeError('Funding amount should be a number')
    .test('max-digits', 'Please enter correct funding amout ', (value) => {
      return !value || value.toString().length <= 18;
    })
    .nullable(),
  industry_type_id: Yup.string().nullable(),
});

export default schema;
