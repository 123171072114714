import { Box, Button, Grid, Paper } from '@mui/material';
import React, { ReactElement, useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import OpportunityClient from '../../api/Opportunity/OpportunityAPI';
import {
  setDetailedMode,
  setDisabledMode,
  setEditMode,
  setOpportunities,
  setRefetchData,
} from '../../store/opportunity/opportunity.slice';
import { setSection } from '../../store/user_sections/userSections.slice';
import styles from '../../styles/styles';
import ViewOpportunities from './ViewOpportunity';
import SearchBox from '../../components/searchBox/SearchBox';

interface IProps {
  intl?: any;
}

const Opportunity: React.FC<IProps> = (): ReactElement => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ability = useSelector((state: any) => state.auth.ability);
  const editData = useSelector((state: any) => state.account.editAccountData);
  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const refetchData = useSelector((state: any) => state.opportunity.refetchData);

  useEffect(() => {
    if (window.location.pathname === '/opportunity') {
      dispatch(
        setSection({
          id: 15,
          name: 'opportunity',
          route: '/opportunity',
        }),
      );
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (refetchData) getOpportunities();
    // eslint-disable-next-line
  }, [refetchData]);

  const getOpportunities = () => {
    if (ability.can('GET', 'Opportunity')) {
      setIsDataLoading(true);

      if (Object.keys(editData).length !== 0 && window.location.pathname !== '/opportunity') {
        OpportunityClient.getOpportunitiesByAccountId(editData.id)
          .then((response: any) => {
            dispatch(setOpportunities(response.data));
            dispatch(setRefetchData(false));
            setIsDataLoading(false);
          })
          .catch((e) => {
            setIsDataLoading(false);
            console.error(e);
          });
      } else {
        OpportunityClient.getOpportunity({})
          .then((response: any) => {
            dispatch(setOpportunities(response.data));
            dispatch(setRefetchData(false));
            setIsDataLoading(false);
          })
          .catch((e) => {
            setIsDataLoading(false);
            console.error(e);
          });
      }
    }
  };

  return (
    <Grid container>
      <Grid item md={12}>
        <Paper
          sx={
            window.location.pathname === '/opportunity'
              ? {
                  padding: '18px 33px',
                  marginRight: '20px',
                }
              : null
          }>
          {window.location.pathname === '/opportunity' ? (
            <Box
              sx={{
                textAlign: 'right',
              }}>
              <Box sx={styles.searchContainer}>
                <SearchBox
                  value={searchTerm}
                  setValue={setSearchTerm}
                  label="Search"
                  variant="outlined"
                  id="search-opportunity-page"
                />
                {ability.can('POST', 'Opportunity') ? (
                  <Button
                    sx={styles.dialogButton}
                    onClick={() => {
                      dispatch(setEditMode(false));
                      dispatch(setDetailedMode(false));
                      dispatch(setDisabledMode(false));
                      localStorage.setItem('OpportunityDetailedMode', 'false');
                      navigate('/opportunity/add');
                    }}>
                    <FormattedMessage id="addOpportunity" />
                  </Button>
                ) : null}
              </Box>
            </Box>
          ) : null}
          <br />
          <ViewOpportunities
            loader={isDataLoading}
            setIsDataLoading={setIsDataLoading}
            searchTerm={searchTerm}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default injectIntl(Opportunity);
