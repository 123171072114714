import { Box, Button, Grid, Paper } from '@mui/material';
import { Country, State } from 'country-state-city';
import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import AccountClient from '../../api/Account/AccountAPI';
import {
  setAccounts,
  setTotalAccounts,
  setDetailedMode,
  setDisabledMode,
  setEditData,
  setEditMode,
  setRefetchData,
} from '../../store/account/account.slice';
import { setSection } from '../../store/user_sections/userSections.slice';
import styles from '../../styles/styles';
import './Account.scss';
import ViewAccounts from './ViewAccounts';
import SearchBox from '../../components/searchBox/SearchBox';

interface IProps {
  intl?: any;
}

const Account: React.FC<IProps> = (): ReactElement => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const ability = useSelector((state: any) => state.auth.ability);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const refetchData = useSelector((state: any) => state.account.refetchData);
  const [paginationModel, setPaginationModel] = useState<{
    page: number;
    pageSize: number;
  }>({
    page: 0,
    pageSize: 20,
  });
  const [offset, setOffset] = useState<number>(paginationModel.page * paginationModel.pageSize);
  const [limit, setLimit] = useState<number>(paginationModel.pageSize);
  const offsetRef = useRef(offset);
  const limitRef = useRef(limit);
  useEffect(() => {
    offsetRef.current = offset;
    limitRef.current = limit;
  }, [offset, limit]);
  useEffect(() => {
    setOffset(paginationModel.page * paginationModel.pageSize);
    setLimit(paginationModel.pageSize);
  }, [paginationModel]);
  useEffect(() => {
    if (window.location.pathname === '/account') {
      dispatch(
        setSection({
          id: 10,
          name: 'account',
          route: '/account',
        }),
      );
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (refetchData && ability.can('GET', 'Account')) getAccounts();
    // eslint-disable-next-line
  }, [refetchData]);

  const getAccounts = () => {
    setIsDataLoading(true);
    const data = {
      offset: paginationModel.page * paginationModel.pageSize,
      limit: paginationModel.pageSize,
    };
    AccountClient.getAccounts(data)
      .then((response: any) => {
        accountsSet(response);
      })
      .catch((e) => {
        setIsDataLoading(false);
        console.error(e);
      });
  };

  const fetchSearchedResults = async (query?: string): Promise<void> => {
    const currentOffset = offsetRef.current;
    const currentLimit = limitRef.current;
    setIsDataLoading(true);
    let responsee;
    if (query) {
      const data = {
        offset: currentOffset,
        limit: currentLimit,
        searchParam: query,
      };
      responsee = AccountClient.getAccounts(data);
    } else {
      const data = {
        offset,
        limit,
      };
      responsee = AccountClient.getAccounts(data);
    }

    responsee
      .then((response: any) => {
        accountsSet(response);
      })
      .catch((e) => {
        setIsDataLoading(false);
        console.error(e);
      });
  };
  const debouncedFetchSearchResults = useCallback(
    _.debounce((query: string) => fetchSearchedResults(query), 500),
    [],
  );
  const accountsSet = (response: any) => {
    response.data.map((row: any) => {
      row.shipping_country = Country.getAllCountries().find(
        (country) => country.name === row.shipping_country,
      )?.isoCode;
      row.billing_country = Country.getAllCountries().find(
        (country) => country.name === row.billing_country,
      )?.isoCode;
      row.shipping_state = State.getStatesOfCountry(row.shipping_country).find(
        (state) => state.name === row.shipping_state,
      )?.isoCode;
      row.billing_state = State.getStatesOfCountry(row.billing_country).find(
        (state) => state.name === row.billing_state,
      )?.isoCode;
      return null;
    });
    dispatch(setRefetchData(false));
    dispatch(setAccounts(response.data));
    dispatch(setTotalAccounts(response.total_items));
    setIsDataLoading(false);
  };
  useEffect(() => {
    if (searchTerm) {
      debouncedFetchSearchResults(searchTerm);
    } else {
      fetchSearchedResults();
      debouncedFetchSearchResults.cancel();
    }
  }, [searchTerm, debouncedFetchSearchResults, offset, limit]);

  useEffect(() => {
    setPaginationModel((prevModel) => ({ ...prevModel, page: 0 }));
  }, [searchTerm]);
  return (
    <Grid container>
      <Grid item md={12}>
        <Paper
          sx={{
            padding: '18px 33px',
            marginRight: '20px',
          }}>
          <Box sx={styles.searchContainer}>
            <SearchBox
              value={searchTerm}
              setValue={setSearchTerm}
              label="Search"
              variant="outlined"
              id="search-accounts-page"
            />
            {ability.can('POST', 'Account') ? (
              <Button
                sx={styles.dialogButton}
                onClick={() => {
                  dispatch(setEditMode(false));
                  dispatch(setEditData({}));
                  dispatch(setDisabledMode(false));
                  dispatch(setDetailedMode(false));
                  localStorage.removeItem('AccountInfo');
                  localStorage.setItem('AccountDetailedMode', 'false');
                  navigate('/account/add');
                }}>
                <FormattedMessage id="addAccount" />
              </Button>
            ) : null}
          </Box>
          <br />
          <ViewAccounts
            loader={isDataLoading}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default injectIntl(Account);
