import AccountClient from '../Account/AccountAPI';
import axiosInstance from '../axiosInstance';

export const PATHS = Object.freeze({
  OPPORTUNITY: `/api/v1/opportunity`,
  ACCOUNT: (id: string, offset: number, limit: number) =>
    `/api/v1/account/${id}/opportunities?offset=${offset}&limit=${limit}`,
  SINGLEOPPORTUNITY: (id: string) => `/api/v1/opportunity/${id}`,
});

interface GetOpportunityOptions {
  searchParam?: string | undefined;
  offset?: number;
  limit?: number;
}

const getOpportunity = async (options: GetOpportunityOptions): Promise<any> => {
  const { searchParam, offset, limit } = options;
  let url = `${PATHS.OPPORTUNITY}`;
  if (limit && searchParam) {
    url = `${PATHS.OPPORTUNITY}?offset=${offset}&limit=${limit}&search=${searchParam}`;
  } else if (!limit && searchParam) {
    url = `${PATHS.OPPORTUNITY}?search=${searchParam}`;
  } else if (limit && !searchParam) {
    url = `${PATHS.OPPORTUNITY}?offset=${offset}&limit=${limit}`;
  } else {
    url = `${PATHS.OPPORTUNITY}`;
  }
  const response = await axiosInstance.get(url);
  return response.data;
};

const getSingleOpportunity = async (id: string): Promise<any> => {
  const url = `${PATHS.OPPORTUNITY}/${id}`;

  const response = await axiosInstance.get(url);
  return response.data;
};
const createOpportunityAndAccount = async (data: {
  name: string;
  id: string;
  userId: string;
  currency: string | null;
}): Promise<any> => {
  const response = await AccountClient.createAccount({ name: data.name, page: 'calculator' });
  if (response.message === 'success' && response.data) {
    const { id } = response.data;
    const { name } = response.data;
    const date = new Date();
    const todayDate = date.toISOString();
    const AccountApidata = {
      account_id: id,
      name,
      owner_id: data.userId,
      currency: data.currency,
      close_date: todayDate,
    };
    const resp = await createOpportunity(AccountApidata);
    if (resp.message === 'success' && resp.data) {
      return resp.data;
    }
  }
  return false;
};

const deleteOpportunity = async (id: string): Promise<any> => {
  const response = await axiosInstance.delete(PATHS.SINGLEOPPORTUNITY(id));
  return response.data;
};

interface GetOpportunitiesByAccountIdOptions {
  id: string;
  offset: number;
  limit: number;
}
const getOpportunitiesByAccountId = async (
  options: GetOpportunitiesByAccountIdOptions,
): Promise<any> => {
  const { id, offset, limit } = options;
  const response = await axiosInstance.get(PATHS.ACCOUNT(id, offset, limit));
  return response.data;
};

const getOpportunityStage = async (): Promise<any> => {
  const response = await axiosInstance.get(`${PATHS.OPPORTUNITY}/stage`);
  return response.data;
};

const editOpportunityStage = async (stageId: any, stage: any): Promise<any> => {
  const response = await axiosInstance.put(`${PATHS.OPPORTUNITY}/stage/${stageId}`, stage);
  return response.data;
};

const addOpportunityStage = async (stage: any): Promise<any> => {
  const response = await axiosInstance.post(`${PATHS.OPPORTUNITY}/stage`, stage);
  return response.data;
};

const getOpportunityType = async (): Promise<any> => {
  const response = await axiosInstance.get(`${PATHS.OPPORTUNITY}/type`);
  return response.data;
};

const editOpportunityType = async (typeId: any, type: any): Promise<any> => {
  const response = await axiosInstance.put(`${PATHS.OPPORTUNITY}/type/${typeId}`, type);
  return response.data;
};

const addOpportunityType = async (type: any): Promise<any> => {
  const response = await axiosInstance.post(`${PATHS.OPPORTUNITY}/type`, type);
  return response.data;
};

const createOpportunity = async (opportunity: any): Promise<any> => {
  const response = await axiosInstance.post(`${PATHS.OPPORTUNITY}`, opportunity);
  return response.data;
};

const editOpportunity = async (oppId: any, opportunity: any): Promise<any> => {
  const response = await axiosInstance.put(`${PATHS.OPPORTUNITY}/${oppId}`, opportunity);
  return response.data;
};

export interface OpportunityAPIClient {
  readonly getOpportunity: (options: GetOpportunityOptions) => Promise<any>;
  readonly getSingleOpportunity: (id: string) => Promise<any>;
  readonly createOpportunityAndAccount: (data: {
    name: string;
    id: string;
    userId: string;
    currency: string | null;
  }) => Promise<any>;
  readonly deleteOpportunity: (id: string) => Promise<any>;
  readonly getOpportunitiesByAccountId: (
    options: GetOpportunitiesByAccountIdOptions,
  ) => Promise<any>;
  readonly getOpportunityStage: () => Promise<any>;
  readonly getOpportunityType: () => Promise<any>;
  readonly createOpportunity: (opportunity: any) => Promise<any>;
  readonly editOpportunity: (oppId: any, opportunity: any) => Promise<any>;
  readonly editOpportunityStage: (stageId: any, stage: any) => Promise<any>;
  readonly editOpportunityType: (typeId: any, type: any) => Promise<any>;
  readonly addOpportunityType: (type: any) => Promise<any>;
  readonly addOpportunityStage: (stage: any) => Promise<any>;
}

const OpportunityClient: OpportunityAPIClient = Object.freeze({
  getOpportunity,
  getSingleOpportunity,
  createOpportunityAndAccount,
  deleteOpportunity,
  getOpportunitiesByAccountId,
  getOpportunityStage,
  getOpportunityType,
  createOpportunity,
  editOpportunity,
  editOpportunityStage,
  editOpportunityType,
  addOpportunityStage,
  addOpportunityType,
});

export default OpportunityClient;
