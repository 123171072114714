import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import TenantsClient from '../../api/Tenant/TenantAPIs';
import commonStyle from '../../styles/commonStyle';
import getColumns from './DealTermsConstants';
import {
  setDealTerms,
  setEditMode,
  setEditData,
  setRefetchData,
  setDealTermsTotalCount,
} from '../../store/deal_terms/dealTerms.Slice';
import ConfirmDialog from '../../components/DialogBox/ConfirmDialog';
import { ISnackBar } from '../../models/common';
import Snackbar from '../../components/Snackbar/Snackbar';

interface IProps {
  searchTerm: string;
  selectedFilter: any;
}

const ViewDealTerms: React.FC<IProps> = ({ searchTerm, selectedFilter }): ReactElement => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dealTerms = useSelector((state: any) => state.dealTerms.dealTerms);
  const totalDealTerms = useSelector((state: any) => state.dealTerms.totalDealTerms);
  const refetchData = useSelector((state: any) => state.dealTerms.refetchData);
  const [loading, setLoading] = useState<boolean>(false);
  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [deleteProdId, setDeleteProdId] = useState('');
  const [deleteProdName, setDeleteProdName] = useState('');
  const [snackbarValues, setSnackBarValues] = useState<ISnackBar>({} as ISnackBar);
  const [paginationModel, setPaginationModel] = useState<{
    page: number;
    pageSize: number;
  }>({
    page: 0,
    pageSize: 20,
  });
  const [offset, setOffset] = useState<number>(paginationModel.page * paginationModel.pageSize);
  const [limit, setLimit] = useState<number>(paginationModel.pageSize);
  const offsetRef = useRef(offset);
  const limitRef = useRef(limit);
  const filterRef = useRef(selectedFilter);
  useEffect(() => {
    offsetRef.current = offset;
    limitRef.current = limit;
  }, [offset, limit]);

  useEffect(() => {
    filterRef.current = selectedFilter;
  }, [selectedFilter]);
  useEffect(() => {
    setOffset(paginationModel.page * paginationModel.pageSize);
    setLimit(paginationModel.pageSize);
  }, [paginationModel]);

  useEffect(() => {
    setPaginationModel((prevModel) => ({ ...prevModel, page: 0 }));
  }, [searchTerm, selectedFilter]);

  const onEditDealTerm = (data: any) => {
    const ac = dealTerms.find((item: any) => item.id === data.id);
    dispatch(setEditData(ac));
    dispatch(setEditMode(true));
    navigate(`/deal-terms/edit/${data.row.id}`);
  };

  const onDeleteProduct = () => {
    setDeleteLoader(true);

    TenantsClient.deleteDealTerm(deleteProdId)
      .then((res) => {
        dispatch(setRefetchData(true));
        setShowDeleteConfirmDialog(false);
        setDeleteLoader(false);
        setTimeout(() => {
          setSnackBarValues({
            display: true,
            type: 'success',
            message: res.message,
          });
        }, 0);
      })
      .catch((err: any) => {
        setDeleteLoader(false);
        setShowDeleteConfirmDialog(false);
        console.error(err);
      });
  };

  const openDeleteModal = (data: any): any => {
    setDeleteProdId(data.row.id);
    setDeleteProdName(data.row.name);
    setShowDeleteConfirmDialog(true);
  };

  const fetchSearchedResults = async (query?: string): Promise<void> => {
    const currentOffset = offsetRef.current;
    const currentLimit = limitRef.current;
    setLoading(true);
    let responsee;

    if (query) {
      const data = {
        searchParam: query,
        offset: currentOffset,
        limit: currentLimit,
        selectedFilter: filterRef.current,
      };
      responsee = TenantsClient.getDealTerms(data);
    } else {
      const data = {
        offset,
        limit,
        selectedFilter: filterRef.current,
      };
      responsee = TenantsClient.getDealTerms(data);
    }

    responsee
      .then((response: any) => {
        if (response.message === 'success') {
          dispatch(setDealTerms(response.data));
          dispatch(setDealTermsTotalCount(response.total_items));
        }
        setLoading(false);
        dispatch(setRefetchData(false));
      })
      .catch((e: any) => {
        setLoading(false);
        dispatch(setRefetchData(false));
        console.log(e, 'failed');
      });
  };

  const debouncedFetchSearchResults = useCallback(
    _.debounce((query: string) => fetchSearchedResults(query), 500),
    [],
  );

  useEffect(() => {
    if (searchTerm) {
      debouncedFetchSearchResults(searchTerm);
    } else {
      fetchSearchedResults();

      debouncedFetchSearchResults.cancel();
    }
  }, [searchTerm, debouncedFetchSearchResults, offset, limit, selectedFilter]);

  useEffect(() => {
    if (refetchData) {
      fetchSearchedResults();
    }
  }, [refetchData]);

  const handlePaginationModelChange = (newModel: { page: number; pageSize: number }) => {
    setPaginationModel(newModel);
  };

  return (
    <>
      <Box sx={{ width: '100%', height: '550px' }}>
        <DataGrid
          rows={loading ? [] : dealTerms}
          columns={getColumns(onEditDealTerm, openDeleteModal)}
          rowCount={totalDealTerms}
          loading={loading}
          pageSizeOptions={[20, 30]}
          pagination
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 20,
                page: 0,
              },
            },
          }}
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={handlePaginationModelChange}
          sx={commonStyle.dateGridStyle}
          disableRowSelectionOnClick
          disableColumnMenu
        />
      </Box>
      {showDeleteConfirmDialog ? (
        <ConfirmDialog
          dialogConfig={{
            name: 'confirmDelete',
            text: 'confirmMessageWithName',
            open: showDeleteConfirmDialog,
            productName: deleteProdName,
            loader: deleteLoader,
            handleConfirm: onDeleteProduct,
            handleCancel: () => {
              setDeleteProdId('');
              setDeleteProdName('');
              setShowDeleteConfirmDialog(false);
            },
          }}
        />
      ) : null}
      {snackbarValues.message ? (
        <Snackbar
          display={snackbarValues.display}
          type={snackbarValues.type}
          message={snackbarValues.message}
          onClose={() => setSnackBarValues({ display: false } as ISnackBar)}
        />
      ) : null}
    </>
  );
};

export default ViewDealTerms;
