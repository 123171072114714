/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable react/jsx-no-bind */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  FormControl,
  Grid,
  Paper,
  Checkbox,
  FormControlLabel,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  Tooltip,
  IconButton,
  CircularProgress,
  Typography,
} from '@mui/material';
// import './calculator.scss';
import DownloadIcon from '@mui/icons-material/Download';

import { CSVLink } from 'react-csv';
import { FormattedMessage, injectIntl } from 'react-intl';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import QuoteComparisonClient from '../../api/PricingCalculator/QuoteComparisonAPI';
import { IQuote, ValueElement, ValueValueClass } from '../../models/quote';
import { handleDownload } from '../../utils/helperService';
import { formatCurrency } from '../../utils/stringUtil';
import Snackbar from '../../components/Snackbar/Snackbar';
import { ISnackBar } from '../../models/common';

const componentStyle = {
  downloadBtn: {
    textDecoration: 'none',
    backgroundColor: '#5d5fef',
    ':hover': { backgroundColor: '#5D5FEF' },
    '&:disabled': {
      color: 'white',
      opacity: 0.5,
    },
    color: '#fff',
    border: 'none',
    fontFamily: 'Helvetica',
    fontWeight: '700',
    borderRadius: '10px',
    padding: '10px 20px',
    marginLeft: 'auto',
    display: 'flex',
  },
  saveBtn: {
    textDecoration: 'none',
    backgroundColor: '#5d5fef',
    ':hover': { backgroundColor: '#5D5FEF' },
    '&:disabled': {
      color: 'white',
      opacity: 0.5,
    },
    color: '#fff',
    border: 'none',
    fontFamily: 'Helvetica',
    fontWeight: '700',
    borderRadius: '10px',
    padding: '10px 20px',
    marginLeft: 'auto',
    display: 'flex',
  },

  csvLink: {
    color: '#fff',
    border: 'none',
    fontFamily: 'Helvetica',
    fontWeight: '700',
  },
  downloadIcon: {
    color: '#5d5fef',
    // background: '#5d5fef',
    cursor: 'pointer',
  },
  csvContainer: {
    display: 'flex',
    justifyContent: 'spaceBetween',
    alignItems: 'center',
    padding: '20px 10px',
    marginBottom: '20px',
  },
  headingContainer: {
    display: 'flex',
    justifyContent: 'spaceBetween',
    alignItems: 'center',
    flexWrap: 'wrap',
  },

  downloadBtnContainer: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  tableCell: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  emptyContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '86vh',
    color: 'red',
    flexDirection: 'column',
    fontFamily: 'Helvetica',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
  },
  centerContain: {
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
  },
  centerContainLessHeight: {
    display: 'flex',
    height: '70vh',
    justifyContent: 'center',
    alignItems: 'center',
  },
  circularStyle: {
    height: '60px',
    width: '60px',
  },
  createQuoteBtn: {
    background: '#5D5FEF',
    color: 'white',
    fontSize: '18px',
    marginTop: '12px',
    paddingX: '20px',
    paddingY: '10px',
    ':hover': { background: '#5D5FEF', color: 'white' },
  },
};

interface QuoteComparisonProps {
  // Add any other props you need for the component
}

interface RowHeaderData {
  name: string;
  id: string;
}

interface RowData {
  id: string;
  type: 'header' | 'data' | 'spacer' | 'total' | 'discounted' | 'Total without discount';
  idex?: number;
  data: (string | number)[] | RowHeaderData[];
}
const QuoteComparison: React.FC<QuoteComparisonProps> = () => {
  interface IAddon {
    id: string;
    name: string;
  }

  interface ITier {
    id: string;
    name: string;
    addons: IAddon[];
    quotes: { [key: string]: any };
    addonData: { [key: string]: any };
  }

  interface IProduct {
    name: string;
    tiers: ITier[];
  }

  interface IDataObj {
    [key: string]: IProduct;
  }
  const location = useLocation();
  const navigate = useNavigate();

  const [quotes, setQuotes] = useState<IQuote[]>([]);
  const [filteredQuotes, setFilteredQuotes] = useState<IQuote[]>([]);
  const [maxTierAddonLength, setMaxTierAddonLength] = useState<number>(0);
  const [tableDataLength, setTableDataLength] = useState<number>(0);
  const [tiersLength, setTiersLength] = useState<number>(0);
  const [tablesData, setTablesData] = useState<any>([]);
  const [csvData, setCsvData] = useState<any>([]);

  const tableRef = useRef<(HTMLTableElement | null)[]>([]);
  const [visibleQuotes, setVisibleQuotes] = useState<{ [key: string]: boolean }>({});
  const [isLoading, setIsLoading] = useState(false);

  const [snackbarValues, setSnackBarValues] = useState<ISnackBar>({} as ISnackBar);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [isComputeData, setIsComputeData] = useState(true);
  const [isQuotesByIdLoading, setIsQuoteByIdLoading] = useState(false);
  const [showContent, setShowContent] = useState(false);
  function handleOnDragEndTableRow(result: any) {
    if (!result.destination) return;
    const tableIndex = parseInt(result.source.droppableId.split('-')[1], 10);
    const tableToEdit = tablesData[tableIndex];

    const temp = tableToEdit[result.destination.index];
    tableToEdit[result.destination.index] = tableToEdit[result.source.index];
    tableToEdit[result.source.index] = temp;

    const newTablesData = Array.from(tablesData);
    for (let i = 0; i < tableToEdit.length; i += 1) {
      tableToEdit[i].idex = i;
    }
    newTablesData[tableIndex] = tableToEdit;
    setTablesData(newTablesData);
  }
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const opportunityId = searchParams.get('opportunityId');
    const ids = searchParams.getAll('ids');
    getQuotes(ids, opportunityId);
  }, [location.search]);

  useEffect(() => {
    generateCsvData();
  }, [tablesData]);

  const getQuotes = async (ids: string[], opportunityId: string | null) => {
    try {
      setIsLoading(true);
      const response = await QuoteComparisonClient.getQuotes(ids, opportunityId);
      if (response.message === 'success' && response.data.length > 0) {
        setQuotes(response?.data);
      }
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);

      setTimeout(() => {
        navigate('/pricing-calculator');
      }, 2000);
    }
  };

  useEffect(() => {
    let updatedVisibleQuotes: any = {};
    if (quotes.length > 0 && quotes.length <= 5) {
      updatedVisibleQuotes = quotes.reduce((acc: { [key: string]: boolean }, quote: IQuote) => {
        acc[quote.id] = true;
        return acc;
      }, {});
    } else if (quotes.length > 0 && quotes.length > 5) {
      updatedVisibleQuotes = quotes.reduce((acc: { [key: string]: boolean }, quote: IQuote) => {
        acc[quote.id] = false;
        return acc;
      }, {});
    }
    setVisibleQuotes(updatedVisibleQuotes);
  }, [quotes]);

  useEffect(
    () => setFilteredQuotes(quotes.filter((quote) => visibleQuotes[quote.id])),
    [visibleQuotes, quotes],
  );
  const groupedByPricebook = useMemo(() => {
    return filteredQuotes.reduce((acc, quote) => {
      if (!acc[quote.price_book_id]) {
        acc[quote.price_book_id] = [];
      }
      acc[quote.price_book_id].push(quote);
      return acc;
    }, {} as { [key: string]: IQuote[] });
  }, [filteredQuotes]);
  const getCoreModelData = useCallback(
    (productId: string, tierId: string, quoteId: string) => {
      const toReturn: {
        qty: number | null;
        discount: number | null;
        total: number | null;
        discountedTotal: number | null;
      } = { qty: null, discount: null, total: null, discountedTotal: null };

      const quoteData = quotes.find((quote) => quote.id === quoteId);
      const quoteDetail = quoteData?.quote_details?.find(
        (detail) => detail.product_id === productId,
      );
      const tier = quoteDetail?.tiers.find((t) => t.tier_id === tierId);
      const core = tier?.details.core;

      const getValue = (key: string, hastack: ValueElement[] | undefined): number | null => {
        if (!hastack) return null;
        const val = hastack.find((v) => v.key === key)?.value;
        function isNumber(value: ValueValueClass | number | undefined): value is number {
          return (val as number) !== undefined;
        }
        if (isNumber(val)) {
          return val;
        }
        return null;
      };

      if (core?.rows.length) {
        const row = core.rows[0];
        const values = row.values ? [...row.values] : [];
        toReturn.qty = getValue('qty', values);
        toReturn.discountedTotal = getValue('discounted_total_price', values);
        toReturn.total = getValue('list_total_price', values);
        toReturn.discount = getValue('discount', values);
      }
      return toReturn;
    },
    [quotes],
  );
  const getAddonsModelData = useCallback(
    (productId: string, tierId: string, quoteId: string, addonId: string) => {
      const toReturn: {
        qty: number | null;
        discount: number | null;
        total: number | null;
        discountedTotal: number | null;
      } = { qty: null, discount: null, total: null, discountedTotal: null };

      const quoteData = quotes.find((quote) => quote.id === quoteId);
      const quoteDetail = quoteData?.quote_details?.find(
        (detail) => detail.product_id === productId,
      );
      const tier = quoteDetail?.tiers.find((t) => t.tier_id === tierId);
      const addons = tier?.details.addons;

      const getValue = (key: string, hastack: ValueElement[] | undefined): number | null => {
        if (!hastack) return null;
        const val = hastack.find((v) => v.key === key)?.value;
        function isNumber(value: ValueValueClass | number | undefined): value is number {
          return (val as number) !== undefined;
        }
        if (isNumber(val)) {
          return val;
        }
        return null;
      };

      if (addons?.rows.length) {
        const row = addons.rows.find((r) => r.addon_id === addonId && r.values.length);
        if (row === undefined) return toReturn;
        const values = row.values ? [...row.values] : [];
        toReturn.qty = getValue('qty', values);
        toReturn.discountedTotal = getValue('discounted_total_price', values);
        toReturn.total = getValue('list_total_price', values);
        toReturn.discount = getValue('discount', values);
      }
      return toReturn;
    },
    [quotes],
  );

  const getUsedProducts = (quoteList: IQuote[]): IDataObj => {
    const dataObj = {} as IDataObj;
    quoteList.forEach((quote: IQuote) => {
      quote.quote_details?.forEach((detail) => {
        if (!dataObj[detail.product_id]) {
          dataObj[detail.product_id] = { name: detail.product_name, tiers: [] };
        }
        detail.tiers.forEach((tier) => {
          const addons = tier.details.addons.rows
            .filter((x) => x.id)
            .map((row) => {
              return { id: row.id as string, name: row.name };
            })
            .filter((x) => x);

          if (
            !dataObj[detail.product_id].tiers.find((t: { id: string }) => t.id === tier.tier_id)
          ) {
            dataObj[detail.product_id].tiers.push({
              id: tier.tier_id,
              name: tier.tier_name,
              addons,
              quotes: {},
              addonData: {},
            });
          } else {
            const existingTier = dataObj[detail.product_id].tiers.find(
              (t: { id: string }) => t.id === tier.tier_id,
            );
            if (existingTier) {
              const newArr = [...existingTier.addons];
              addons.forEach((addon) => {
                if (!newArr.find((a) => a.id === addon.id)) {
                  newArr.push(addon);
                }
              });
              existingTier.addons = newArr;
            }
          }
        });
      });
    });

    Object.keys(dataObj).forEach((productId) => {
      dataObj[productId].tiers.forEach((tier: ITier) => {
        quoteList.forEach((quote: IQuote) => {
          if (!tier.quotes[quote.id]) {
            tier.quotes[quote.id] = {};
          }
          const coreModelData = getCoreModelData(productId, tier.id, quote.id);
          tier.quotes[quote.id] = {
            qty: coreModelData.qty,
            discount: coreModelData.discount,
            total: coreModelData.total,
            discountedTotal: coreModelData.discountedTotal,
            netTotal: coreModelData.discountedTotal,
          };

          tier.addons.forEach((addon) => {
            if (!tier.addonData[quote.id]) {
              tier.addonData[quote.id] = {};
            }
            if (!tier.addonData[quote.id][addon.id]) {
              tier.addonData[quote.id][addon.id] = {};
            }
            const addonsModelData = getAddonsModelData(productId, tier.id, quote.id, addon.id);
            tier.addonData[quote.id][addon.id] = {
              qty: addonsModelData.qty,
              discount: addonsModelData.discount,
              total: addonsModelData.total,
              discountedTotal: addonsModelData.discountedTotal,
            };
            tier.quotes[quote.id].netTotal += addonsModelData.discountedTotal;
          });
        });
      });
    });
    const keys = Object.keys(dataObj);
    keys.forEach((key) => {
      const { tiers } = dataObj[key];

      tiers.forEach((tier) => {
        if (maxTierAddonLength < tier.addons.length) {
          // eslint-disable-next-line no-plusplus
          setMaxTierAddonLength((prev) => Math.max(prev, tier.addons.length));
        }
      });
    });
    return dataObj;
  };
  const generateCsvData = () => {
    const tableDataArray: string[][][] = [];
    tableRef.current.forEach((table: any) => {
      if (table === null) return;

      const tableData = Array.from(table.rows).map((row: any) => {
        return Array.from(row.cells).map((cell: any) => {
          // Optionally add a zero-width space (\u200B) to force Excel to treat it as text
          return cell.innerText.startsWith(' ') || Number.isNaN(cell.innerText)
            ? cell.innerText
            : `\u200B${cell.innerText}`; // Prepend with zero-width space for numbers
        });
      });

      tableDataArray.push(tableData);
    });

    const combinedData: string[][] = [];
    let length = 0;
    if (tableDataArray.length > 0) {
      length = checkTableDataArrayLength(tableDataArray);
    }
    if (length > 0) {
      for (let rowIndex = 0; rowIndex < length; rowIndex += 1) {
        const combinedRow: string[] = [];
        tableDataArray.forEach((tableData) => {
          combinedRow.push(...tableData[rowIndex]);
        });
        combinedData.push(combinedRow);
      }
    }
    setCsvData(combinedData);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const checkTableDataArrayLength = (mainArray: any) => {
    let minLengthArr = mainArray[0]!.length;

    for (let i = 1; i < mainArray.length; i += 1) {
      if (mainArray[i].length < minLengthArr) {
        minLengthArr = mainArray[i].length;
      }
    }
    return minLengthArr;
  };

  function createArraysForBinding() {
    const allData = Object.keys(groupedByPricebook).map((key: string) => {
      const group = groupedByPricebook[key];
      const usedProducts = getUsedProducts(group);
      const usedProductsKey = Object.keys(usedProducts);
      const usedProductsVal = usedProducts[usedProductsKey[0]];
      setTiersLength((prev) => Math.max(prev, usedProductsVal.tiers.length));
      const tableData: RowData[] = [];

      const headerRow: RowData = {
        id: 'header',
        type: 'header',
        data: group.map((quote) => ({ name: quote.name, id: quote.id })),
      };
      tableData.push(headerRow);

      Object.keys(usedProducts).map((productId) =>
        usedProducts[productId].tiers.map(
          (tier: {
            id: any;
            name: string;
            quotes: { [key: string]: any };
            addonData: { [key: string]: any };
            addons: { id: string; name: string }[];
          }) => {
            const coreRow: RowData = {
              id: `${productId}-${tier.id}-core`,
              type: 'data',
              idex: 0,
              data: [
                usedProducts[productId].tiers.length === 1 && tier.name === 'Default_Tier'
                  ? usedProducts[productId].name
                  : `${usedProducts[productId].name} (${tier.name})`,

                ...group.map((quote) => {
                  const quoteData = tier.quotes[quote.id];
                  return quoteData ? formatCurrency(quoteData.discountedTotal) : '';
                }),
              ],
            };
            tableData.push(coreRow);
            const addonRows = tier.addons.map(
              // eslint-disable-next-line no-return-assign
              (addon) =>
                ({
                  id: `${productId}-${tier.id}-${addon.id}`,
                  type: 'data',
                  idex: 0,
                  data: [
                    addon.name,
                    ...group.map((quote) => {
                      const quoteData = tier.addonData[quote.id][addon.id];
                      return quoteData ? formatCurrency(quoteData.discountedTotal) : '';
                    }),
                  ],
                } as RowData),
            );

            tableData.push(...addonRows);
            if (tier.addons.length < maxTierAddonLength + 1) {
              const spacers = Array.from({ length: maxTierAddonLength - tier.addons.length }).map(
                // eslint-disable-next-line no-return-assign
                (_, idx) =>
                  ({
                    id: `${productId}-${tier.id}-${idx}-spacers`,
                    idex: 0,
                    type: 'spacer',
                    data: [(group.length + 1).toString()],
                  } as RowData),
              );
              tableData.push(...spacers);
            }
            const totalRow: RowData = {
              id: `${productId}-${tier.id}-total`,
              type: 'total',
              idex: 0,
              data: [
                'Total',
                ...group.map((quote) => {
                  const quoteData = tier.quotes[quote.id];
                  return quoteData ? formatCurrency(quoteData.netTotal) : '';
                }),
              ],
            };
            tableData.push(totalRow);

            const spaceRow: RowData = {
              id: tier.name,
              type: 'spacer',
              idex: 0,
              data: [(group.length + 1).toString()],
            };
            tableData.push(spaceRow);

            return {};
          },
        ),
      );

      for (let i = 0; i < tableData.length; i += 1) {
        tableData[i].idex = i;
      }
      // if (group.length > 0) {
      //   const totalPrice: RowData = {
      //     id: 'Total without discount',
      //     type: 'Total without discount',
      //     idex: tableData.length,
      //     data: [
      //       'Total without discount',
      //       ...group.map((quote) => formatCurrency(+quote.total_price)),
      //     ],
      //   };
      //   tableData.push(totalPrice);
      // }
      // if (group.length > 0) {
      //   const totalDiscounted: RowData = {
      //     id: 'discounted',
      //     type: 'discounted',
      //     idex: tableData.length,
      //     data: ['Discount', ...group.map((quote) => `${+quote.discount}%`)],
      //   };
      //   tableData.push(totalDiscounted);
      // }
      if (group.length > 0) {
        const grandTotal: RowData = {
          id: `grand-total`,
          type: 'total',
          idex: tableData.length,
          data: [
            'Net Total',
            ...group.map((quote) => {
              const quoteData = quote.total_discounted_price;
              return quoteData ? formatCurrency(quoteData) : '';
            }),
          ],
        };
        tableData.push(grandTotal);
      }

      setTableDataLength((prev) => Math.max(prev, tableData.length));
      if (tableDataLength > 0) {
        for (let i = 1; i < tableDataLength - tableData.length; i += 1) {
          const spaceRow: RowData = {
            id: `empty-space-rows ${i}`,
            type: 'spacer',
            idex: tableData.length,
            data: [(group.length + 1).toString()],
          };
          tableData.push(spaceRow);
        }
        if (tableDataLength > tableData.length) {
          if (usedProductsVal.tiers.length > 0 && tiersLength > usedProductsVal.tiers.length) {
            const tierLengthDiff = (tiersLength - usedProductsVal.tiers.length) * 2;
            for (let i = 0; i <= tierLengthDiff; i += 1) {
              const spaceRow: RowData = {
                id: `empty-space-rows---${tableData.length}`,
                type: 'spacer',
                idex: tableData.length,
                data: [(group.length + 1).toString()],
              };
              tableData.push(spaceRow);
            }
          }
        }
      }
      return tableData;
    });
    setTablesData(allData);
    setIsComputeData(false);
  }
  const saveQuotes = async () => {
    setIsSaveLoading(true);
    const quotesIds: string[] = [];
    filteredQuotes.map((quote: any) => quotesIds.push(quote.id));
    const data = {
      quote_list: quotesIds,
      comparison_data: tablesData,
    };
    try {
      const response = await QuoteComparisonClient.saveQuotesByIds(data);
      if (response.message === 'Quote comparison saved successfully') {
        setSnackBarValues({
          display: true,
          message: response.message,
          type: 'success',
        });
      }
      setIsSaveLoading(false);
    } catch (error) {
      console.error(error);
      setIsSaveLoading(false);
    }
  };

  const getQuotesByIds = async () => {
    const quotesIds: string[] = [];

    filteredQuotes.map((quote: any) => quotesIds.push(quote.id));
    if (filteredQuotes.length > 0) {
      const quotesIds: string[] = [];
      filteredQuotes.map((quote: any) => quotesIds.push(quote.id));
      const commaSeparatedFilteredQuotes = quotesIds.join();

      setIsQuoteByIdLoading(true);
      try {
        const response = await QuoteComparisonClient.getQuotesByIds(commaSeparatedFilteredQuotes);
        if (response.message === 'success' && response.data.comparison_data) {
          setTablesData(response.data.comparison_data);
          setIsComputeData(false);
        } else {
          setIsComputeData(true);
        }
        setIsQuoteByIdLoading(false);
      } catch (error) {
        console.error(error);
        setIsComputeData(true);
        setIsQuoteByIdLoading(false);
      }
    }
  };

  useEffect(() => {
    if (filteredQuotes.length > 0) {
      getQuotesByIds();
    }
  }, [filteredQuotes]);
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (quotes.length === 0) {
      const timer = setTimeout(() => {
        setShowContent(true);
      }, 1500);

      // Cleanup the timer on component unmount
      return () => clearTimeout(timer);
    }
  }, [quotes.length]);

  useEffect(() => {
    if (Object.keys(groupedByPricebook).length > 0 && isComputeData) createArraysForBinding();
  }, [groupedByPricebook, isComputeData]);
  if (isLoading) {
    return (
      <Box sx={componentStyle.centerContain}>
        <CircularProgress sx={componentStyle.circularStyle} />
      </Box>
    );
  }
  return quotes.length > 0 ? (
    <Box
      sx={{
        marginRight: '20px',
        marginLeft: '10px',
        height: '86vh',
        borderRadius: '6px',
      }}>
      <Grid container>
        <Grid item md={12}>
          <Paper>
            <Box sx={componentStyle.csvContainer}>
              <Box sx={componentStyle.headingContainer}>
                {quotes.map((quote) => (
                  <FormControl key={`${quote.id} ${quote.name}`}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={!!visibleQuotes[quote.id]}
                          // defaultChecked
                          onChange={(e) =>
                            setVisibleQuotes({ ...visibleQuotes, [quote.id]: e.target.checked })
                          }
                          disabled={isQuotesByIdLoading}
                        />
                      }
                      label={quote.name}
                    />
                  </FormControl>
                ))}
              </Box>
              {filteredQuotes && filteredQuotes.length > 0 && (
                <Box sx={componentStyle.downloadBtnContainer}>
                  <Button
                    size="small"
                    sx={componentStyle.downloadBtn}
                    onClick={saveQuotes}
                    disabled={isQuotesByIdLoading}>
                    {isSaveLoading ? (
                      <CircularProgress sx={{ color: 'white' }} size={24} />
                    ) : (
                      <FormattedMessage id="save" />
                    )}
                  </Button>
                  <Button
                    size="small"
                    sx={componentStyle.downloadBtn}
                    onClick={generateCsvData}
                    disabled={isQuotesByIdLoading}>
                    <CSVLink
                      data={csvData}
                      filename="comparison.csv"
                      style={{ color: 'white', textDecoration: 'none', fontWeight: '700' }}
                      className="csvLink">
                      <FormattedMessage id="download" />
                    </CSVLink>
                  </Button>
                </Box>
              )}
            </Box>
          </Paper>
        </Grid>
      </Grid>

      {!isQuotesByIdLoading ? (
        tablesData && tablesData.length > 0 && filteredQuotes.length > 0 ? (
          <Grid container>
            <Grid item md={12}>
              <Paper>
                <Box sx={{ display: 'flex', gap: 6, overflow: 'scroll' }}>
                  {tablesData.map((table: any, index: number) => {
                    return (
                      // eslint-disable-next-line react/no-array-index-key, react/jsx-no-bind

                      <Table
                        ref={(el) => {
                          tableRef.current[index] = el;
                        }}
                        sx={{ width: 'auto' }}
                        // eslint-disable-next-line react/no-array-index-key
                        key={`table-${index}`}>
                        {table.length > 0 &&
                          table
                            .filter((x: any) => x.type === 'header')
                            .map((tableRow: RowData) => {
                              return (
                                <TableHead key={`${tableRow.id}-header`}>
                                  <TableRow>
                                    <TableCell width="400px" />
                                    {(tableRow.data as RowHeaderData[]).map(
                                      (headerData: RowHeaderData) => {
                                        return (
                                          <TableCell
                                            key={headerData.id}
                                            className="quoteName"
                                            align="left"
                                            sx={componentStyle.tableCell}>
                                            <Box sx={componentStyle.header}>
                                              {headerData.name}
                                              <Tooltip title="Click to download quote">
                                                <IconButton
                                                  onClick={() =>
                                                    handleDownload(headerData.id, headerData.name)
                                                  }
                                                  sx={{ ...componentStyle.downloadIcon }}>
                                                  <DownloadIcon />
                                                </IconButton>
                                              </Tooltip>
                                            </Box>
                                          </TableCell>
                                        );
                                      },
                                    )}
                                  </TableRow>
                                </TableHead>
                              );
                            })}
                        {/* eslint-disable-next-line react/no-array-index-key  */}
                        <DragDropContext onDragEnd={handleOnDragEndTableRow} key={index}>
                          <Droppable droppableId={`table-${index}`}>
                            {(provided) => (
                              <TableBody ref={provided.innerRef} {...provided.droppableProps}>
                                {table.length > 0 &&
                                  table
                                    .filter((x: any) => x.type !== 'header')
                                    .map((tableRow: any) => {
                                      if (tableRow.type === 'data') {
                                        return (
                                          <Draggable
                                            key={tableRow.id}
                                            draggableId={tableRow.id}
                                            index={tableRow.idex}>
                                            {(provided) => (
                                              <TableRow
                                                ref={provided.innerRef}
                                                {...provided.dragHandleProps}
                                                {...provided.draggableProps}
                                                key={tableRow.id}>
                                                {tableRow.data.map((val: any, idx: number) => {
                                                  return (
                                                    <TableCell
                                                      // align={idx === 0 ? 'left' : 'right'}
                                                      // eslint-disable-next-line react/no-array-index-key
                                                      key={`${tableRow.id}-${idx}`}
                                                      sx={componentStyle.tableCell}>
                                                      {val}
                                                    </TableCell>
                                                  );
                                                })}
                                              </TableRow>
                                            )}
                                          </Draggable>
                                        );
                                      }
                                      // if (tableRow.type === 'Total without discount') {
                                      //   return (
                                      //     <Draggable
                                      //       key={`Total without discount-${tableRow.idex}`}
                                      //       draggableId={tableRow.id}
                                      //       index={tableRow.idex}>
                                      //       {(provided) => (
                                      //         <TableRow
                                      //           ref={provided.innerRef}
                                      //           {...provided.dragHandleProps}
                                      //           {...provided.draggableProps}>
                                      //           {tableRow.data.map((val: any, idx: number) => {
                                      //             return (
                                      //               <TableCell
                                      //                 align={idx === 0 ? 'left' : 'right'}
                                      //                 sx={componentStyle.tableCell}
                                      //                 // eslint-disable-next-line react/no-array-index-key
                                      //                 key={`${tableRow.id}Total without discount`}>
                                      //                 {val}
                                      //               </TableCell>
                                      //             );
                                      //           })}
                                      //         </TableRow>
                                      //       )}
                                      //     </Draggable>
                                      //   );
                                      // }
                                      // if (tableRow.type === 'discounted') {
                                      //   return (
                                      //     <Draggable
                                      //       key="total-discounted-price"
                                      //       draggableId={tableRow.id}
                                      //       index={tableRow.idex}>
                                      //       {(provided) => (
                                      //         <TableRow
                                      //           ref={provided.innerRef}
                                      //           {...provided.dragHandleProps}
                                      //           {...provided.draggableProps}>
                                      //           {tableRow.data.map((val: any, idx: number) => {
                                      //             return (
                                      //               <TableCell
                                      //                 align={idx === 0 ? 'left' : 'right'}
                                      //                 sx={componentStyle.tableCell}
                                      //                 // eslint-disable-next-line react/no-array-index-key
                                      //                 key={`${idx}-only-discount`}>
                                      //                 {val}
                                      //               </TableCell>
                                      //             );
                                      //           })}
                                      //         </TableRow>
                                      //       )}
                                      //     </Draggable>
                                      //   );
                                      // }
                                      if (tableRow.type === 'total') {
                                        return (
                                          <Draggable
                                            key={`${tableRow.id}-total-${tableRow.idex}`}
                                            draggableId={tableRow.id}
                                            index={tableRow.idex}>
                                            {(provided) => (
                                              <TableRow
                                                ref={provided.innerRef}
                                                {...provided.dragHandleProps}
                                                {...provided.draggableProps}>
                                                {tableRow.data.map((val: any, idx: number) => {
                                                  return (
                                                    <TableCell
                                                      // align={idx === 0 ? 'left' : 'right'}
                                                      sx={componentStyle.tableCell}
                                                      // eslint-disable-next-line react/no-array-index-key
                                                      key={`${tableRow.id}-${idx}`}>
                                                      {val}
                                                    </TableCell>
                                                  );
                                                })}
                                              </TableRow>
                                            )}
                                          </Draggable>
                                        );
                                      }
                                      if (tableRow.type === 'spacer') {
                                        return (
                                          <Draggable
                                            key={tableRow.id}
                                            draggableId={tableRow.id}
                                            index={tableRow.idex}>
                                            {(provided) => (
                                              <TableRow
                                                ref={provided.innerRef}
                                                {...provided.dragHandleProps}
                                                {...provided.draggableProps}>
                                                {table[0].data?.length > 0 &&
                                                  Array.from({
                                                    length: table[0].data.length + 1,
                                                  }).map((_, index) => (
                                                    <TableCell
                                                      // eslint-disable-next-line react/no-array-index-key
                                                      key={index}
                                                      sx={componentStyle.tableCell}
                                                      // colSpan={
                                                      //   table[0].data?.length
                                                      //     ? table[0].data.length + 1
                                                      //     : 10
                                                      // }
                                                    >
                                                      &nbsp;
                                                    </TableCell>
                                                  ))}
                                              </TableRow>
                                            )}
                                          </Draggable>
                                        );
                                      }

                                      return null;
                                    })}
                                {provided.placeholder}
                              </TableBody>
                            )}
                          </Droppable>
                        </DragDropContext>
                      </Table>
                    );
                  })}
                </Box>
              </Paper>
            </Grid>
          </Grid>
        ) : showContent ? (
          <Box sx={componentStyle.emptyContainer}>
            <Typography>Please select some quotes to see comparison.</Typography>
          </Box>
        ) : null
      ) : (
        <Box sx={componentStyle.centerContainLessHeight}>
          <CircularProgress sx={componentStyle.circularStyle} />
        </Box>
      )}

      {snackbarValues.message ? (
        <Snackbar
          display={snackbarValues.display}
          type={snackbarValues.type}
          message={snackbarValues.message}
          onClose={() => setSnackBarValues({ display: false } as ISnackBar)}
        />
      ) : null}
    </Box>
  ) : showContent ? (
    <Box sx={componentStyle.emptyContainer}>
      <Typography>This opportunity does not have any quotes.</Typography>
      <Typography>Create some quotes before comparing them</Typography>
      <Button sx={componentStyle.createQuoteBtn} onClick={() => navigate('/pricing-calculator')}>
        Create Quote
      </Button>
    </Box>
  ) : null;
};

export default injectIntl(QuoteComparison);
