import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { GridActionsCellItem, GridColDef } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { Tooltip } from '@mui/material';
import { defaultDateFormat } from '../../constants/constants';

const getColumns = (onEditDealTerm: any, openDeleteModal: any): GridColDef[] => {
  return [
    {
      field: 'name',
      headerAlign: 'left',
      align: 'left',
      headerName: 'Name',
      flex: 1.8,
      renderCell: (params: any) => (
        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {params.row.name}
        </div>
      ),
    },
    {
      field: 'label',
      headerAlign: 'left',
      align: 'left',
      headerName: 'Label',
      flex: 1.8,
      renderCell: (params: any) => (
        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {params.row.label}
        </div>
      ),
    },
    {
      field: 'component',
      headerAlign: 'left',
      align: 'left',
      headerName: 'Component',
      flex: 0.6,
      renderCell: (params: any) => (
        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {params.row.component}
        </div>
      ),
    },
    {
      field: 'created_on',
      headerAlign: 'left',
      align: 'left',
      headerName: 'Creation Date',
      flex: 0.6,
      renderCell: (params: any) => {
        return dayjs(params.row.created_on).format(defaultDateFormat).toString();
      },
    },
    {
      field: 'options',
      headerAlign: 'left',
      align: 'left',
      headerName: 'Options',
      flex: 0.4,
      renderCell: (params: any) => {
        if (params.row.component === 'select' || params.row.component === 'radio') {
          return params.row.options.length > 0 ? (
            <Tooltip
              title={params.row.options
                .filter((item: any) => item.is_active === true)
                .map((item: any) => (
                  <div>{item.label}</div>
                ))}>
              <div>{params.row.active_options}</div>
            </Tooltip>
          ) : (
            0
          );
        }
        return null;
      },
    },
    {
      field: 'is_required',
      headerAlign: 'left',
      align: 'left',
      headerName: 'Required',
      flex: 0.5,
    },
    {
      field: 'actions',
      headerName: '',
      align: 'right',
      flex: 0.5,
      renderCell: (params: any) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            key={`${params.row.id}edit`}
            onClick={() => onEditDealTerm(params)}
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            key={`${params.row.id}delete`}
            onClick={() => openDeleteModal(params)}
          />,
          // <GridActionsCellItem
          //   icon={
          //     params?.row?.is_enabled ? (
          //       <Tooltip title="disable">
          //         <ToggleOffIcon fontSize="large" color="disabled" />
          //       </Tooltip>
          //     ) : (
          //       <Tooltip title="enable">
          //         <ToggleOnIcon fontSize="large" />
          //       </Tooltip>
          //     )
          //   }
          //   label="enable"
          //   key={`${params.row.id}disable`}
          //   onClick={() => handleEnablingDealTerm(params)}
          // />,
        ];
      },
    },
  ];
};

export default getColumns;
