/* eslint-disable no-nested-ternary */
import React, { ReactElement, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';

import { Formik } from 'formik';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import { useLocation, useNavigate } from 'react-router-dom';
import pageStyle from '../SalesForceIntegration/pageStyle';
import { ISnackBar } from '../../models/common';
import styles from '../../styles/styles';
import { REGEX } from '../../utils/helperService';
import Snackbar from '../../components/Snackbar/Snackbar';
import { setEditMode, setEditData, setRefetchData } from '../../store/deal_terms/dealTerms.Slice';
import TenantsClient from '../../api/Tenant/TenantAPIs';
import ConfirmDialog from '../../components/DialogBox/ConfirmDialog';

const dealTermOptions: {
  label: string;
  value: string;
}[] = [
  {
    label: 'Text Field',
    value: 'text-field',
  },
  {
    label: 'Dropdown',
    value: 'select',
  },
  {
    label: 'Checkbox',
    value: 'checkbox',
  },
  {
    label: 'Radio Button',
    value: 'radio',
  },
  {
    label: 'Date Picker',
    value: 'date-picker',
  },
];
interface IProps {
  intl: any;
}
const AddDealTerms: React.FC<IProps> = ({ intl }): ReactElement => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const isEditMode = useSelector((state: any) => state.dealTerms.isEditMode);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [saveNewOptionLoader, setSaveNewOptionLoader] = useState(false);
  const [deleteProdId, setDeleteProdId] = useState('');
  const [deleteProdName, setDeleteProdName] = useState('');
  const [loading, setLoading] = useState(false);
  const [editLoader, setEditLoader] = useState(false);
  const [radioLoader, setRadioLoader] = useState(false);
  const [disableOptionLoader, setDisableOptionLoader] = useState(false);
  const [editdealTermInfo, setEditdealTermInfo] = useState<any>(undefined);
  const [snackbarValues, setSnackBarValues] = useState<ISnackBar>({} as ISnackBar);
  const pathnameSegments = location.pathname.split('/');
  const lastSegment = pathnameSegments[pathnameSegments.length - 1];
  const [inputValues, setInputValues] = useState<any>();
  const [isAddNewBtnDisabled, setIsAddNewBtnDisabled] = useState<boolean>(false);
  const [disabledArr, setDisabledArr] = useState<number[]>([]);
  const [isDefaultOption, setIsDefaultOption] = useState<number>();
  const [disableEnableOptionIndex, setDisableEnableOptionIndex] = useState<number>(0);
  const [disableSetDefaultIndex, setDisableSetDefaultIndex] = useState<number>(0);
  useEffect(() => {
    if (editdealTermInfo && editdealTermInfo?.options && editdealTermInfo?.options?.length > 0) {
      const indexArray = Array.from(
        { length: editdealTermInfo?.options?.length },
        (_, index) => index,
      );
      setDisabledArr(indexArray);
    }
  }, [editdealTermInfo, editdealTermInfo?.options]);

  useEffect(() => {
    if (lastSegment !== 'add') {
      getDealTerm();
    }
  }, [lastSegment]);

  useEffect(() => {
    if (!loading && editdealTermInfo && editdealTermInfo.options.length) {
      setInputValues(
        editdealTermInfo?.options?.map((item: any) => ({ input1: item.label, input2: item.value })),
      );
    }
    if (window.location.pathname !== '/deal-terms/add' && editdealTermInfo !== undefined) {
      dispatch(setEditMode(true));
    }
  }, [editdealTermInfo]);

  const getDealTerm = () => {
    if (lastSegment !== 'add') {
      setLoading(true);

      TenantsClient.getDealTerm(lastSegment)
        .then((response) => {
          if (response.message === 'success') {
            setEditdealTermInfo(response.data[0]);
          }
          setLoading(false);
        })
        .catch((e) => {
          console.error(e);
          setLoading(false);
          navigate('/not-found');
        });
    }
  };
  const submit = async (values: any) => {
    setIsSaving(true);
    const data: any = {
      key: values.name,
      label: values.label,
      type: values.component,
      is_required: values.is_required,
      is_enabled: values.is_enabled,
    };
    if (isEditMode && editdealTermInfo.id) {
      data.id = editdealTermInfo.id;
      TenantsClient.editDealTerms(data)
        .then((res) => {
          if (res.message === 'success') {
            getDealTerm();
            setTimeout(() => {
              setSnackBarValues({
                display: true,
                type: 'success',
                message: intl.formatMessage({ id: 'editDealTermSuccess' }),
              });
            }, 0);
          }
          setIsSaving(false);
        })
        .catch((e) => {
          console.log(e, ' failed');
          setIsSaving(false);
        });
    } else if (!isEditMode) {
      TenantsClient.addDealTerms(data)
        .then((res) => {
          if (res.message === 'success') {
            dispatch(setRefetchData(true));

            const { id } = res.data;
            navigate(`/deal-terms/edit/${id}`);
            setTimeout(() => {
              setSnackBarValues({
                display: true,
                type: 'success',
                message: intl.formatMessage({ id: 'addDealTermSuccess' }),
              });
            }, 0);
          }
          setIsSaving(false);
        })
        .catch((e) => {
          console.log(e, 'get api resp');
          setIsSaving(false);
        });
    }
  };

  const closeOpportunityDialog = () => {
    dispatch(setEditMode(false));
    dispatch(setEditData({}));
    navigate('/deal-terms');
  };

  const openDeleteModal = (data: any): any => {
    setDeleteProdId(data.id);
    setDeleteProdName(data.label);
    setShowDeleteConfirmDialog(true);
  };

  const onDeleteProduct = () => {
    setDeleteLoader(true);
    const data = {
      dealTermId: editdealTermInfo.id,
      dealTermOptionId: deleteProdId,
    };

    TenantsClient.deleteDealTermsOption(data)
      .then(() => {
        dispatch(setRefetchData(true));
        setShowDeleteConfirmDialog(false);
        setDeleteLoader(false);
        const updatedOptions = editdealTermInfo.options.filter(
          (option: any) => option.id !== data.dealTermOptionId,
        );
        setEditdealTermInfo({
          ...editdealTermInfo,
          options: updatedOptions,
        });
        const newDisabledArr = disabledArr.slice(0, -1);
        setDisabledArr(newDisabledArr);

        setTimeout(() => {
          setSnackBarValues({
            display: true,
            type: 'success',
            message: intl.formatMessage({ id: 'deleteDealTermOptionSuccess' }),
          });
        }, 0);
      })
      .catch((err: any) => {
        setDeleteLoader(false);
        setShowDeleteConfirmDialog(false);
        console.error(err);
      });
  };

  const onEditOption = (val: any, index: number) => {
    if (disabledArr.includes(index)) {
      const arr = disabledArr.filter((item) => item !== index);
      setDisabledArr(arr);
    }
  };
  const onEditSaveOption = (val: any, index: number) => {
    setEditLoader(true);
    const data = {
      dealTermId: editdealTermInfo.id,
      dealTermOptionId: val.id,
      label: inputValues[index].input1,
      value: inputValues[index].input2,
    };

    TenantsClient.editDealTermsOption(data)
      .then((res) => {
        if (res.message === 'success') {
          setEditLoader(false);
          setTimeout(() => {
            setSnackBarValues({
              display: true,
              type: 'success',
              message: intl.formatMessage({ id: 'editDealTermOptionSuccess' }),
            });
          }, 0);
          const updatedOptions = editdealTermInfo.options.map((option: any) => {
            if (option.id === data.dealTermOptionId) {
              return {
                ...option,
                label: data.label,
                value: data.value,
              };
            }
            return option;
          });
          setEditdealTermInfo({
            ...editdealTermInfo,
            options: updatedOptions,
          });
        }
      })
      .catch((err: any) => {
        setEditLoader(false);
        console.error(err);
      });
  };

  const handleRadioDefaultOption = (val: any, item: any, index: number) => {
    setRadioLoader(true);
    setDisableSetDefaultIndex(index);
    const data = {
      dealTermId: editdealTermInfo.id,
      dealTermOptionId: item.id,
      is_default: val.target.checked,
    };

    TenantsClient.editIsDefaultOption(data)
      .then((res) => {
        if (res.message === 'success') {
          const updatedOptions = editdealTermInfo.options.map((option: any) => {
            return {
              ...option,
              is_default: option.id === data.dealTermOptionId ? data.is_default : false,
            };
          });
          setEditdealTermInfo({
            ...editdealTermInfo,
            options: updatedOptions,
          });
          setRadioLoader(false);

          setTimeout(() => {
            setSnackBarValues({
              display: true,
              type: 'success',
              message: intl.formatMessage({ id: 'DefaultDealTermOptionSuccess' }),
            });
          }, 0);
        }
      })
      .catch((err: any) => {
        setRadioLoader(false);
        console.error(err);
      });
  };
  const handleInputChange = (index: number, inputType: string, event: any) => {
    if (inputValues) {
      const newValues: any = [...inputValues];
      if (event.target.value.length < 25) {
        newValues[index] = {
          ...newValues[index],
          [inputType]: event.target.value,
        };
        setInputValues(newValues);
      }
    }
  };

  const handleSaveNewOption = async (values: any) => {
    setSaveNewOptionLoader(true);
    const data = {
      id: editdealTermInfo.id,
      label: values.key,
      value: values.value,
    };

    TenantsClient.addDealTermsOptions(data)
      .then(() => {
        setIsAddNewBtnDisabled(false);
        getDealTerm();
        setSaveNewOptionLoader(false);

        setTimeout(() => {
          setSnackBarValues({
            display: true,
            type: 'success',
            message: intl.formatMessage({ id: 'addDealTermOptionSuccess' }),
          });
        }, 0);
      })
      .catch((err: any) => {
        setSaveNewOptionLoader(false);
        console.error(err);
      });
  };

  const handleActiveInactiveOption = (item: any, index: number) => {
    setDisableEnableOptionIndex(index);
    const data = {
      dealTermId: editdealTermInfo.id,
      dealTermOptionId: item.id,
      isActive: !item.is_active,
    };

    setDisableOptionLoader(true);

    TenantsClient.disableDealTermsOption(data)
      .then((res) => {
        if (res.message === 'success') {
          const updatedOptions = editdealTermInfo.options.map((option: any) => {
            if (option.id === data.dealTermOptionId) {
              return {
                ...option,
                is_active: !item.is_active,
              };
            }
            return option;
          });
          setEditdealTermInfo({
            ...editdealTermInfo,
            options: updatedOptions,
          });
          setDisableOptionLoader(false);
          setTimeout(() => {
            setSnackBarValues({
              display: true,
              type: 'success',
              message: intl.formatMessage({ id: 'editDealTermOptionSuccess' }),
            });
          }, 0);
        }
      })
      .catch((err: any) => {
        setDisableOptionLoader(false);
        console.error(err);
      });
  };

  const handleCancel = (index: number) => {
    setInputValues(
      editdealTermInfo?.options?.map((item: any) => ({ input1: item.label, input2: item.value })),
    );
    setDisabledArr((prev) => [...prev, index]);
  };

  useEffect(() => {
    if (editdealTermInfo && editdealTermInfo.options && editdealTermInfo.options.length > 0) {
      const isDefaultIndex = editdealTermInfo.options.findIndex(
        (item: any) => item.is_default === true,
      );
      if (isDefaultIndex !== -1) {
        setIsDefaultOption(isDefaultIndex);
      }
    }
  }, [editdealTermInfo]);
  return (
    <>
      {!loading ? (
        <Paper sx={pageStyle.inputContainer}>
          <Box sx={pageStyle.cardTitle}>
            {isEditMode ? (
              <FormattedMessage id="editDealTerm" />
            ) : (
              <FormattedMessage id="addDealTerm" />
            )}
          </Box>
          <Formik
            onSubmit={submit}
            enableReinitialize
            initialValues={
              isEditMode
                ? editdealTermInfo
                : {
                    name: '',
                    label: '',
                    component: '',
                    is_required: true,
                    is_enabled: true,
                  }
            }
            validationSchema={Yup.object({
              name: Yup.string()
                .trim()
                .required('Name is required')
                .matches(
                  REGEX,
                  'name should have more than 1 character, start with an alphabet and can have special characters like .,-_&',
                ),
              label: Yup.string()
                .trim()
                .required('Label is required')
                .matches(
                  REGEX,
                  'Name should have more than 1 character, start with an alphabet and can have special characters like .,-_&',
                ),
              component: Yup.string()
                .trim()
                .required('Please select component')
                .matches(
                  REGEX,
                  'It should have more than 1 character, start with an alphabet and can have special characters like .,-_&',
                ),
              is_required: Yup.boolean(),
              is_enabled: Yup.boolean(),
            })}>
            {({ values, errors, touched, setFieldTouched, setFieldValue, handleSubmit }) => {
              return (
                <>
                  <Box component="form" noValidate sx={{ mt: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item md={4}>
                        <Typography className="fieldHeader">
                          <FormattedMessage id="name" />
                          <span style={{ color: 'red' }}> *</span>
                        </Typography>
                        <TextField
                          label={<FormattedMessage id="name" />}
                          id="name"
                          name="name"
                          variant="outlined"
                          fullWidth
                          value={values?.name}
                          disabled={isSaving || lastSegment !== 'add'}
                          sx={{ marginTop: '10px' }}
                          onChange={(u) => {
                            if (u.target.value.length < 25) {
                              setFieldValue('name', u.target.value);
                            }
                            setTimeout(() => setFieldTouched('name', true));
                          }}
                          error={Boolean(errors.name && touched.name)}
                          // helperText={errors.name as string}
                        />
                      </Grid>
                      <Grid item md={4}>
                        <Typography className="fieldHeader">
                          <FormattedMessage id="label" />
                          <span style={{ color: 'red' }}> *</span>
                        </Typography>
                        <TextField
                          label={<FormattedMessage id="label" />}
                          id="label"
                          name="label"
                          variant="outlined"
                          fullWidth
                          value={values?.label}
                          disabled={isSaving || lastSegment !== 'add'}
                          sx={{ marginTop: '10px' }}
                          onChange={(u) => {
                            if (u.target.value.length < 25) {
                              setFieldValue('label', u.target.value);
                            }
                            setTimeout(() => setFieldTouched('label', true));
                          }}
                          error={Boolean(errors.label && touched.label)}
                          // helperText={errors.label as string}
                        />
                      </Grid>
                      <Grid item md={4}>
                        <Typography className="fieldHeader">
                          <FormattedMessage id="type" />
                          <span style={{ color: 'red' }}> *</span>
                        </Typography>
                        <FormControl
                          error={Boolean(errors.component && touched.component)}
                          fullWidth
                          sx={{ marginTop: '10px' }}>
                          <InputLabel id="type">
                            <FormattedMessage id="type" />
                          </InputLabel>
                          <Select
                            labelId="type"
                            label={<FormattedMessage id="type" />}
                            id="component"
                            name="component"
                            fullWidth
                            value={values?.component}
                            disabled={isSaving || lastSegment !== 'add'}
                            onChange={(u) => {
                              setFieldValue('component', u.target.value);
                              setTimeout(() => setFieldTouched('component', true));
                            }}>
                            {dealTermOptions.map((item: any) => (
                              <MenuItem value={item.value} key={item.value}>
                                {item.value}
                              </MenuItem>
                            ))}
                          </Select>

                          {errors.component && touched.component ? (
                            <FormHelperText>{errors.component as string}</FormHelperText>
                          ) : null}
                        </FormControl>
                      </Grid>

                      <Grid item md={4}>
                        <FormControl fullWidth sx={{ marginTop: '10px' }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                id="is_required"
                                name="is_required"
                                checked={values?.is_required}
                                onChange={(e) => {
                                  setFieldValue('is_required', e.target.checked);
                                  setFieldTouched('is_required', true);
                                }}
                              />
                            }
                            label="Required"
                          />
                        </FormControl>
                      </Grid>

                      <Grid item md={4}>
                        <FormControl fullWidth sx={{ marginTop: '10px' }}>
                          <FormControlLabel
                            control={
                              <Checkbox
                                id="is_enabled"
                                name="is_enabled"
                                checked={values?.is_enabled}
                                onChange={(e) => {
                                  setFieldValue('is_enabled', e.target.checked);
                                  setFieldTouched('is_enabled', true);
                                }}
                              />
                            }
                            label="Enabled"
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Box
                      sx={{
                        textAlign: 'right',
                        marginTop: '10px',
                      }}>
                      <Button
                        sx={{ ...styles.dialogButton, marginRight: 2 }}
                        onClick={closeOpportunityDialog}>
                        <FormattedMessage id="back" />
                      </Button>
                      <Button
                        disabled={isSaving}
                        sx={styles.dialogButton}
                        onClick={() => handleSubmit()}>
                        {isSaving ? (
                          <CircularProgress color="inherit" size={24} />
                        ) : (
                          <FormattedMessage id="save" />
                        )}
                      </Button>
                    </Box>
                  </Box>

                  <br />
                </>
              );
            }}
          </Formik>
          {((editdealTermInfo && editdealTermInfo.options.length > 0) || isAddNewBtnDisabled) && (
            <Box sx={{ mt: 1 }}>
              <Grid container spacing={2}>
                <Grid item md={4}>
                  <Typography className="fieldHeader">
                    <FormattedMessage id="label" />
                    <span style={{ color: 'red' }}> *</span>
                  </Typography>
                </Grid>
                <Grid item md={4}>
                  <Typography className="fieldHeader">
                    <FormattedMessage id="value" />
                    <span style={{ color: 'red' }}> *</span>
                  </Typography>
                </Grid>
                <Grid item md={4} />
              </Grid>
            </Box>
          )}
          {editdealTermInfo &&
            (editdealTermInfo?.component === 'select' || editdealTermInfo?.component === 'radio') &&
            editdealTermInfo.options &&
            inputValues &&
            isEditMode &&
            editdealTermInfo.options.length > 0 &&
            editdealTermInfo.options.map((item: any, index: number) => {
              const isOptionEditable = editdealTermInfo.options.length !== disabledArr.length;
              const isLoading =
                radioLoader ||
                disableOptionLoader ||
                editLoader ||
                deleteLoader ||
                isOptionEditable ||
                isAddNewBtnDisabled;
              return (
                <Box sx={{ mt: 1 }} key={item.id}>
                  <Grid container spacing={2}>
                    <Grid item md={4}>
                      <TextField
                        label={<FormattedMessage id="label" />}
                        id="label"
                        name={item.value}
                        variant="outlined"
                        fullWidth
                        disabled={disabledArr.includes(index)}
                        value={
                          inputValues &&
                          inputValues.length > 0 &&
                          inputValues[index] &&
                          inputValues[index].input1
                            ? inputValues[index].input1
                            : ''
                        }
                        onChange={(event) => handleInputChange(index, 'input1', event)}
                        sx={{ marginTop: '10px' }}
                        // onChange={(e) => handleOptionChange(e, item.id, 'label', i)}
                      />
                    </Grid>
                    <Grid item md={4}>
                      <TextField
                        label={<FormattedMessage id="value" />}
                        id="value"
                        name="value"
                        variant="outlined"
                        fullWidth
                        disabled={disabledArr.includes(index)}
                        value={
                          inputValues &&
                          inputValues.length > 0 &&
                          inputValues[index] &&
                          inputValues[index].input2
                            ? inputValues[index].input2
                            : ''
                        }
                        onChange={(event) => handleInputChange(index, 'input2', event)}
                        sx={{ marginTop: '10px' }}
                      />
                    </Grid>
                    <Grid
                      item
                      md={4}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '10px',
                      }}>
                      {disabledArr.includes(index) && (
                        <>
                          <Box sx={{ marginLeft: '10px' }}>
                            <label
                              htmlFor={`${index}radio`}
                              style={{
                                display: 'flex',
                                gap: '5px',
                                minWidth: '78px',
                                alignItems: 'center',
                              }}>
                              <input
                                type="radio"
                                name="default_value"
                                value={index}
                                id={`${index}radio`}
                                checked={index === isDefaultOption}
                                disabled={isLoading}
                                onChange={(e) => handleRadioDefaultOption(e, item, index)}
                              />

                              {radioLoader && disableSetDefaultIndex === index ? (
                                <CircularProgress color="inherit" size={24} />
                              ) : (
                                <Box sx={{ minWidth: '78px' }}>
                                  <FormattedMessage id="setDefault" />
                                </Box>
                              )}
                            </label>
                          </Box>
                          <Button onClick={() => onEditOption(item, index)} disabled={isLoading}>
                            <Tooltip title="Edit">
                              <EditIcon />
                            </Tooltip>
                          </Button>
                          <Button onClick={() => openDeleteModal(item)} disabled={isLoading}>
                            <Tooltip title="Delete">
                              <DeleteIcon color={isLoading ? 'disabled' : 'error'} />
                            </Tooltip>
                          </Button>
                          <Button
                            onClick={() => handleActiveInactiveOption(item, index)}
                            disabled={isLoading}>
                            {disableOptionLoader && disableEnableOptionIndex === index ? (
                              <CircularProgress color="inherit" size={24} />
                            ) : item.is_active ? (
                              <Tooltip title="To disable this option click here">
                                <ToggleOnIcon fontSize="large" />
                              </Tooltip>
                            ) : (
                              <Tooltip title="To enable this option click here">
                                <ToggleOffIcon fontSize="large" color="disabled" />
                              </Tooltip>
                            )}
                          </Button>{' '}
                        </>
                      )}
                      {!disabledArr.includes(index) && (
                        <>
                          <Button
                            onClick={() => onEditSaveOption(item, index)}
                            disabled={editLoader || deleteLoader}>
                            {editLoader && !disabledArr.includes(index) ? (
                              <CircularProgress color="inherit" size={24} />
                            ) : (
                              <FormattedMessage id="save" />
                            )}
                          </Button>
                          <Button onClick={() => handleCancel(index)} disabled={editLoader}>
                            <FormattedMessage id="cancel" />
                          </Button>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              );
            })}
          {(editdealTermInfo?.component === 'select' || editdealTermInfo?.component === 'radio') &&
            isEditMode &&
            !isAddNewBtnDisabled && (
              <Box sx={{ marginTop: '20px' }}>
                <Button
                  sx={styles.dialogButton}
                  disabled={
                    radioLoader ||
                    editdealTermInfo.options.length !== disabledArr.length ||
                    editLoader ||
                    deleteLoader ||
                    disableOptionLoader ||
                    isAddNewBtnDisabled
                  }
                  onClick={() => setIsAddNewBtnDisabled(true)}>
                  <FormattedMessage id="addNewOption" />
                </Button>
              </Box>
            )}
          {isAddNewBtnDisabled ? (
            <Formik
              onSubmit={handleSaveNewOption}
              enableReinitialize
              initialValues={{
                key: '',
                value: '',
              }}
              validationSchema={Yup.object({
                key: Yup.string()
                  .trim()
                  .required('Label is required')
                  .matches(
                    REGEX,
                    'Label should have more than 1 character, start with an alphabet and can have special characters like .,-_&',
                  ),
                value: Yup.string()
                  .trim()
                  .required('Value is required')
                  .matches(
                    REGEX,
                    'Value should have more than 1 character, start with an alphabet and can have special characters like .,-_&',
                  ),
              })}>
              {({ values, errors, touched, setFieldTouched, setFieldValue, handleSubmit }) => {
                return (
                  <>
                    <Box component="form" noValidate sx={{ mt: 1 }}>
                      <Grid container spacing={2}>
                        <Grid item md={4}>
                          <TextField
                            label={<FormattedMessage id="label" />}
                            id="label"
                            name="key"
                            variant="outlined"
                            fullWidth
                            value={values?.key}
                            disabled={saveNewOptionLoader}
                            sx={{ marginTop: '10px' }}
                            onChange={(u) => {
                              if (u.target.value.length < 25) {
                                setFieldValue('key', u.target.value);
                              }
                              setTimeout(() => setFieldTouched('key', true));
                            }}
                            error={Boolean(errors.key && touched.key)}
                            helperText={errors.key as string}
                          />
                        </Grid>
                        <Grid item md={4}>
                          <Box>
                            <TextField
                              label={<FormattedMessage id="value" />}
                              id="value"
                              name="value"
                              variant="outlined"
                              fullWidth
                              value={values?.value}
                              disabled={saveNewOptionLoader}
                              sx={{ marginTop: '10px' }}
                              onChange={(u) => {
                                if (u.target.value.length < 25) {
                                  setFieldValue('value', u.target.value);
                                }
                                setTimeout(() => setFieldTouched('value', true));
                              }}
                              error={Boolean(errors.value && touched.value)}
                              helperText={errors.value as string}
                            />
                          </Box>
                        </Grid>
                        <Grid
                          item
                          md={4}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '10px',
                          }}>
                          <Button disabled={saveNewOptionLoader} onClick={() => handleSubmit()}>
                            {saveNewOptionLoader ? (
                              <CircularProgress color="inherit" size={24} />
                            ) : (
                              <FormattedMessage id="save" />
                            )}
                          </Button>
                          <Button onClick={() => setIsAddNewBtnDisabled(false)}>
                            <DeleteIcon color="error" />
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                    <br />
                  </>
                );
              }}
            </Formik>
          ) : null}
        </Paper>
      ) : (
        <Box
          sx={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress size={24} />
        </Box>
      )}

      {showDeleteConfirmDialog ? (
        <ConfirmDialog
          dialogConfig={{
            name: 'confirmDelete',
            text: 'confirmMessageWithName',
            open: showDeleteConfirmDialog,
            productName: deleteProdName,
            loader: deleteLoader,
            handleConfirm: onDeleteProduct,

            handleCancel: () => {
              setDeleteProdId('');
              setDeleteProdName('');
              setShowDeleteConfirmDialog(false);
            },
          }}
        />
      ) : null}

      {snackbarValues.message ? (
        <Snackbar
          display={snackbarValues.display}
          type={snackbarValues.type}
          message={snackbarValues.message}
          onClose={() => setSnackBarValues({ display: false } as ISnackBar)}
        />
      ) : null}
    </>
  );
};
export default injectIntl(AddDealTerms);
