import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface DealTermState {
  dealTerms: any[];
  totalDealTerms: number;
  isEditMode: boolean;
  editDealTermsData: any;
  refetchData: boolean;
}

export const initialState: DealTermState = {
  dealTerms: [],
  totalDealTerms: 0,
  isEditMode: false,
  editDealTermsData: {},
  refetchData: false,
};

export const dealTermsSlice = createSlice({
  name: 'dealTerms',
  initialState,
  reducers: {
    setDealTerms: (state, action: PayloadAction<any>) => {
      state.dealTerms = action.payload;
    },
    setDealTermsTotalCount: (state, action: PayloadAction<any>) => {
      state.totalDealTerms = action.payload;
    },

    setEditMode: (state, action: PayloadAction<any>) => {
      state.isEditMode = action.payload;
    },
    setEditData: (state, action: PayloadAction<any>) => {
      state.editDealTermsData = action.payload;
    },
    setRefetchData: (state, action: PayloadAction<any>) => {
      state.refetchData = action.payload;
    },
  },
});

export const { setDealTerms, setDealTermsTotalCount, setEditMode, setEditData, setRefetchData } =
  dealTermsSlice.actions;
