import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  Link,
} from '@mui/material';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { Formik } from 'formik';
import React, { ReactElement, useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import AccountClient from '../../api/Account/AccountAPI';
import OpportunityClient from '../../api/Opportunity/OpportunityAPI';
import UserClient from '../../api/Users/UserAPIs';
import Snackbar from '../../components/Snackbar/Snackbar';
import { ISnackBar } from '../../models/common';
import {
  setDetailedMode,
  setDisabledMode,
  setEditMode,
} from '../../store/opportunity/opportunity.slice';
import { setSection } from '../../store/user_sections/userSections.slice';
import styles from '../../styles/styles';
import { REGEX } from '../../utils/helperService';
import DealHub from '../DealHub/DealHub';
import pageStyle from '../SalesForceIntegration/pageStyle';
import './Opportunity.scss';
import currencyList from '../../constants/currencyArray';

const AddOpportunity: React.FC<any> = ({ intl }): ReactElement => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [oppStageList, setOppStageList] = useState<any>([]);
  const [oppTypeList, setOppTypeList] = useState<any>([]);
  const [accountList, setAccountList] = useState<any>([]);
  const [userList, setUserList] = useState<any>([]);
  const ability = useSelector((state: any) => state.auth.ability);
  const isEditMode = useSelector((state: any) => state.opportunity.isEditMode);
  const isDisabledMode = useSelector((state: any) => state.opportunity.isDisabledMode);
  const isDetailedMode = useSelector((state: any) => state.opportunity.isDetailedMode);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isOpploading, setIsOppLoading] = useState(false);
  const [isOppError, setIsOppError] = useState(false);
  const [snackbarValues, setSnackBarValues] = useState<ISnackBar>({} as ISnackBar);
  const [oppData, setOppData] = useState<any>({});
  const localStorageOpportunityDetailedMode = localStorage.getItem('OpportunityDetailedMode');
  const pathnameSegments = location.pathname.split('/');
  const lastSegment = pathnameSegments[pathnameSegments.length - 1];
  useEffect(() => {
    if (lastSegment !== 'add') {
      getOpportunityData();
    }
  }, [lastSegment]);

  useEffect(() => {
    getOppStages();
    getOppTypes();
    getAccounts();
    getUsers();

    if (localStorageOpportunityDetailedMode === 'true') {
      dispatch(setDisabledMode(true));
      dispatch(setDetailedMode(true));
    }
    if (window.location.href.includes('/opportunity/')) {
      dispatch(
        setSection({
          id: 15,
          name: 'opportunity',
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getOppStages = () => {
    OpportunityClient.getOpportunityStage()
      .then((data) => setOppStageList(data.data))
      .catch((error: any) => console.error(error));
  };

  const getOppTypes = () => {
    OpportunityClient.getOpportunityType()
      .then((data) => setOppTypeList(data.data))
      .catch((error: any) => console.error(error));
  };

  const getAccounts = () => {
    AccountClient.getAccounts({})
      .then((data) => setAccountList(data.data))
      .catch((error: any) => console.error(error));
  };

  const getOpportunityData = () => {
    if (lastSegment !== 'add') {
      setIsOppLoading(true);

      OpportunityClient.getSingleOpportunity(lastSegment)
        .then((response) => {
          if (response.message === 'success') {
            dispatch(setEditMode(true));
            setOppData(response.data);
          }
          setIsOppLoading(false);
        })
        .catch((e) => {
          console.error(e);
          setIsOppLoading(false);
          setIsOppError(true);
          setTimeout(() => {
            navigate('/opportunity');
          }, 2000);
        });
    }
  };

  const getUsers = () => {
    UserClient.getUsers()
      .then((data) => setUserList(data.data))
      .catch((error) => console.error(error));
  };

  const closeOpportunityDialog = () => {
    dispatch(setEditMode(false));
    setOppData({});
    dispatch(setDisabledMode(false));
    dispatch(setDetailedMode(false));
    window.history.back();
  };

  const handleEdit = () => {
    dispatch(setDisabledMode(false));
  };

  const submit = async (values: any) => {
    try {
      values.close_date = dayjs(values.close_date).toISOString();
      setIsSaving(true);
      if (isEditMode) {
        const response = await OpportunityClient.editOpportunity(values.id, values);
        if (response.message === 'success') {
          setSnackBarValues({
            display: true,
            message: intl.formatMessage({ id: 'opportunityUpdated' }),
            type: 'success',
          });
          setIsSaving(false);
        }
      } else {
        const response = await OpportunityClient.createOpportunity(values);
        if (response.message === 'success') {
          setSnackBarValues({
            display: true,
            message: intl.formatMessage({ id: 'opportunityCreated' }),
            type: 'success',
          });
          setIsSaving(false);
          navigate('/opportunity');
        }
      }
    } catch (e) {
      setIsSaving(false);
      console.error(e);
    }
  };
  const tenentCurrency = localStorage.getItem('currency');
  return (
    <>
      {!isOpploading && !isOppError ? (
        <Paper sx={pageStyle.inputContainer}>
          <Box sx={pageStyle.cardTitle}>
            {isEditMode
              ? intl.formatMessage({ id: 'editOpportunity' })
              : intl.formatMessage({ id: 'addOpportunity' })}
          </Box>
          <Formik
            onSubmit={submit}
            enableReinitialize
            initialValues={
              isEditMode
                ? oppData
                : {
                    name: '',
                    account_id: accountList.length === 1 ? accountList[0].id : '',
                    owner_id: userList.length === 1 ? userList[0].id : '',
                    type_id: oppTypeList.length === 1 ? oppTypeList[0].id : '',
                    stage_id: oppStageList.length === 1 ? oppStageList[0].id : '',
                    currency: tenentCurrency || '',
                  }
            }
            validationSchema={Yup.object({
              name: Yup.string()
                .trim()
                .required('Please enter name')
                .max(30, 'must be less than 30 characters')
                .matches(
                  REGEX,
                  'Name should have more than 1 character, start with an alphabet and can have special characters like .,-_&',
                ),
              amount: Yup.number()
                .nullable()
                .test('max-digits', 'Please enter correct amount.', (value) => {
                  if (value === null || value === undefined) return true; // skip validation if empty
                  return value.toString().replace('.', '').length <= 18;
                }),

              probability: Yup.number()
                .nullable()
                .test('max-digits', 'Please enter correct probability.', (value) => {
                  if (value === null || value === undefined) return true; // skip validation if empty
                  return value.toString().replace('.', '').length <= 18;
                }),
              close_date: Yup.date().nullable(),
              description: Yup.string().max(80, 'must be less than 80 characters').nullable(),
              account_id: Yup.string()
                // .max(30, 'must be less than 30 characters')
                .required('Please select account'),
              owner_id: Yup.string()
                // .max(30, 'must be less than 30 characters')
                .required('Please select owner'),
              type_id: Yup.string().nullable(),
              stage_id: Yup.string().nullable(),
              currency: Yup.string().trim().required('Please select a Currency'),
            })}>
            {({ values, errors, touched, setFieldTouched, setFieldValue, handleSubmit }) => {
              return (
                <>
                  <Box component="form" noValidate sx={{ mt: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item md={4}>
                        <Typography className="fieldHeader">
                          <FormattedMessage id="accountName" />
                          <span style={{ color: 'red' }}> *</span>

                          {ability.can('POST', 'Account') ? (
                            <Link href="/account/add">+</Link>
                          ) : null}
                        </Typography>
                        <FormControl
                          error={Boolean(errors.account_id && touched.account_id)}
                          fullWidth
                          sx={{ marginTop: '10px' }}>
                          <InputLabel id="accountName">
                            <FormattedMessage id="accountName" />
                          </InputLabel>
                          <Select
                            labelId="accountName"
                            label={<FormattedMessage id="accountName" />}
                            id="accountName"
                            name="account_id"
                            fullWidth
                            value={values.account_id}
                            disabled={oppData.op_external_id || isDisabledMode}
                            onChange={(u) => {
                              setFieldValue('account_id', u.target.value);
                              setTimeout(() => setFieldTouched('account_id', true));
                            }}>
                            {accountList.map((account: any) => (
                              <MenuItem value={account.id} key={account.id}>
                                {account.name}
                              </MenuItem>
                            ))}
                          </Select>

                          {errors.account_id && touched.account_id ? (
                            <FormHelperText>{errors.account_id as string}</FormHelperText>
                          ) : null}
                        </FormControl>
                      </Grid>
                      <Grid item md={4}>
                        <Typography className="fieldHeader">
                          <FormattedMessage id="name" />
                          <span style={{ color: 'red' }}> *</span>
                        </Typography>
                        <TextField
                          label={<FormattedMessage id="name" />}
                          id="name"
                          name="name"
                          variant="outlined"
                          fullWidth
                          value={values.name}
                          disabled={oppData.op_external_id || isDisabledMode}
                          sx={{ marginTop: '10px' }}
                          onChange={(u) => {
                            setFieldValue('name', u.target.value);
                            setTimeout(() => setFieldTouched('name', true));
                          }}
                          error={Boolean(errors.name && touched.name)}
                          helperText={errors.name as string}
                        />
                      </Grid>
                      <Grid item md={4}>
                        <Typography className="fieldHeader">
                          <FormattedMessage id="amount" />
                        </Typography>
                        <TextField
                          label={<FormattedMessage id="amount" />}
                          id="amount"
                          name="amount"
                          variant="outlined"
                          value={values.amount}
                          type="number"
                          fullWidth
                          disabled={oppData.op_external_id || isDisabledMode}
                          sx={{ marginTop: '10px' }}
                          onChange={(u) => {
                            setFieldValue('amount', u.target.value);
                            setTimeout(() => setFieldTouched('amount', true));
                          }}
                          error={Boolean(errors.amount && touched.amount)}
                          helperText={errors.amount as string}
                        />
                      </Grid>
                      <Grid item md={4}>
                        <Typography className="fieldHeader">
                          <FormattedMessage id="probability" />
                        </Typography>
                        <TextField
                          label={<FormattedMessage id="probability" />}
                          id="probability"
                          name="probability"
                          variant="outlined"
                          type="number"
                          fullWidth
                          value={values.probability}
                          disabled={oppData.op_external_id || isDisabledMode}
                          sx={{ marginTop: '10px' }}
                          onChange={(u) => {
                            setFieldValue('probability', u.target.value);
                            setTimeout(() => setFieldTouched('probability', true));
                          }}
                          error={Boolean(errors.probability && touched.probability)}
                          helperText={errors.probability as string}
                        />
                      </Grid>

                      <Grid item md={4}>
                        <Typography className="fieldHeader">
                          <FormattedMessage id="closeDate" />
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DesktopDatePicker
                            label={<FormattedMessage id="closeDate" />}
                            value={dayjs(values.close_date)}
                            sx={{ marginTop: '10px', width: '100%' }}
                            disablePast
                            format="MM/DD/YYYY"
                            disabled={oppData.op_external_id || isDisabledMode}
                            onChange={(u: any) => {
                              setFieldValue('close_date', u);
                              setTimeout(() => setFieldTouched('close_date', true));
                            }}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid item md={4}>
                        <Typography className="fieldHeader">
                          <FormattedMessage id="description" />
                        </Typography>
                        <TextField
                          multiline
                          fullWidth
                          label={<FormattedMessage id="description" />}
                          id="description"
                          name="description"
                          variant="outlined"
                          value={values.description}
                          disabled={oppData.op_external_id || isDisabledMode}
                          sx={{ marginTop: '10px' }}
                          onChange={(u) => {
                            setFieldValue('description', u.target.value);
                            setTimeout(() => setFieldTouched('description', true));
                          }}
                          error={Boolean(errors.description && touched.description)}
                          helperText={errors.description as string}
                        />
                      </Grid>

                      <Grid item md={4}>
                        <Typography className="fieldHeader">
                          <FormattedMessage id="ownerName" />
                          <span style={{ color: 'red' }}> *</span>
                        </Typography>
                        <FormControl
                          error={Boolean(errors.owner_id && touched.owner_id)}
                          fullWidth
                          sx={{ marginTop: '10px' }}>
                          <InputLabel id="ownerName">
                            <FormattedMessage id="ownerName" />
                          </InputLabel>
                          <Select
                            labelId="ownerName"
                            label={<FormattedMessage id="ownerName" />}
                            id="ownerName"
                            name="owner_id"
                            fullWidth
                            value={values.owner_id}
                            disabled={oppData.op_external_id || isDisabledMode}
                            onChange={(u) => {
                              setFieldValue('owner_id', u.target.value);
                              setTimeout(() => setFieldTouched('owner_id', true));
                            }}>
                            {userList.map((user: any) => (
                              <MenuItem value={user.id} key={user.id}>
                                {user.name}
                              </MenuItem>
                            ))}
                          </Select>
                          {errors.owner_id && touched.owner_id ? (
                            <FormHelperText>{errors.owner_id as string}</FormHelperText>
                          ) : null}
                        </FormControl>
                      </Grid>
                      <Grid item md={4}>
                        <Typography className="fieldHeader">
                          <FormattedMessage id="typeName" />
                        </Typography>
                        <FormControl
                          error={Boolean(errors.type_id && touched.type_id)}
                          fullWidth
                          sx={{ marginTop: '10px' }}>
                          <InputLabel id="typeName">
                            <FormattedMessage id="typeName" />
                          </InputLabel>
                          <Select
                            labelId="typeName"
                            label={<FormattedMessage id="typeName" />}
                            id="typeName"
                            name="type_id"
                            fullWidth
                            value={values.type_id}
                            disabled={oppData.op_external_id || isDisabledMode}
                            onChange={(u) => {
                              setFieldValue('type_id', u.target.value);
                              setTimeout(() => setFieldTouched('type_id', true));
                            }}>
                            {oppTypeList.map((oppType: any) => (
                              <MenuItem value={oppType.id} key={oppType.id}>
                                {oppType.name}
                              </MenuItem>
                            ))}
                          </Select>
                          {errors.type_id && touched.type_id ? (
                            <FormHelperText>{errors.type_id as string}</FormHelperText>
                          ) : null}
                        </FormControl>
                      </Grid>
                      <Grid item md={4}>
                        <Typography className="fieldHeader">
                          <FormattedMessage id="stageName" />
                        </Typography>
                        <FormControl
                          error={Boolean(errors.stage_id && touched.stage_id)}
                          fullWidth
                          sx={{ marginTop: '10px' }}>
                          <InputLabel id="stageName">
                            <FormattedMessage id="stageName" />
                          </InputLabel>
                          <Select
                            labelId="stageName"
                            label={<FormattedMessage id="stageName" />}
                            id="stageName"
                            name="stage_id"
                            fullWidth
                            value={values.stage_id}
                            disabled={oppData.op_external_id || isDisabledMode}
                            onChange={(u) => {
                              setFieldValue('stage_id', u.target.value);
                              setTimeout(() => setFieldTouched('stage_id', true));
                            }}>
                            {oppStageList.map((oppStage: any) => (
                              <MenuItem value={oppStage.id} key={oppStage.id}>
                                {oppStage.name}
                              </MenuItem>
                            ))}
                          </Select>
                          {errors.stage_id && touched.stage_id ? (
                            <FormHelperText>{errors.stage_id as string}</FormHelperText>
                          ) : null}
                        </FormControl>
                      </Grid>
                      <Grid item md={4}>
                        <Typography className="fieldHeader">
                          <FormattedMessage id="currency" />
                          <span style={{ color: 'red' }}> *</span>
                        </Typography>
                        <FormControl
                          error={Boolean(errors.currency && touched.currency)}
                          fullWidth
                          sx={{ marginTop: '10px' }}>
                          <InputLabel id="currencyList">
                            <FormattedMessage id="currency" />
                          </InputLabel>
                          <Select
                            labelId="currency"
                            label={intl.formatMessage({ id: 'currency' })}
                            id="currency"
                            name="currency"
                            fullWidth
                            value={values.currency || ''}
                            disabled={isDisabledMode}
                            onChange={(u) => {
                              setFieldValue('currency', u.target.value);
                              setTimeout(() => setFieldTouched('currency', true));
                            }}>
                            {currencyList.map((item: any) => (
                              <MenuItem value={item.code} key={`${item.name}(${item.code})`}>
                                {`${item.name}(${item.code})`}
                              </MenuItem>
                            ))}
                          </Select>
                          {errors.currency && touched.currency ? (
                            <FormHelperText>{errors.currency as string}</FormHelperText>
                          ) : null}
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Box>
                  {isDetailedMode ? (
                    <Box
                      sx={{
                        textAlign: 'right',
                        marginTop: '10px',
                      }}>
                      <Button sx={styles.dialogButton} onClick={handleEdit}>
                        <FormattedMessage id="edit" />
                      </Button>
                    </Box>
                  ) : (
                    <Box sx={{ marginTop: '10px', textAlign: 'right' }}>
                      <Button
                        sx={{ ...styles.dialogButton, marginRight: 2 }}
                        onClick={closeOpportunityDialog}>
                        <FormattedMessage id="back" />
                      </Button>
                      <Button
                        disabled={isSaving || oppData.op_external_id}
                        sx={styles.dialogButton}
                        onClick={() => handleSubmit()}>
                        {isSaving ? (
                          <CircularProgress color="inherit" size={24} />
                        ) : (
                          <FormattedMessage id="save" />
                        )}
                      </Button>
                    </Box>
                  )}
                  <br />
                  {isDetailedMode ? (
                    <>
                      <DealHub opportunityId={oppData.id} />
                      <br />
                      <Box sx={{ marginTop: '10px', textAlign: 'right' }}>
                        <Button
                          sx={{ ...styles.dialogButton, marginRight: 2 }}
                          onClick={closeOpportunityDialog}>
                          <FormattedMessage id="back" />
                        </Button>
                        <Button
                          disabled={isSaving || oppData.op_external_id || isDisabledMode}
                          sx={styles.dialogButton}
                          onClick={() => handleSubmit()}>
                          {isSaving ? (
                            <CircularProgress color="inherit" size={24} />
                          ) : (
                            <FormattedMessage id="save" />
                          )}
                        </Button>
                      </Box>
                    </>
                  ) : null}
                </>
              );
            }}
          </Formik>
        </Paper>
      ) : (
        <Box
          sx={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress size={24} />
        </Box>
      )}
      {snackbarValues.message ? (
        <Snackbar
          display={snackbarValues.display}
          type={snackbarValues.type}
          message={snackbarValues.message}
          onClose={() => setSnackBarValues({ display: false } as ISnackBar)}
        />
      ) : null}
    </>
  );
};

export default injectIntl(AddOpportunity);
