import EditIcon from '@mui/icons-material/Edit';
import { Box, Button } from '@mui/material';
import { DataGrid, GridActionsCellItem, GridColDef } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import React, { ReactElement, useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Snackbar from '../../components/Snackbar/Snackbar';
import { defaultDateFormat } from '../../constants/constants';
import { ISnackBar } from '../../models/common';
import {
  setDetailedMode,
  setDisabledMode,
  setEditData,
  setEditMode,
} from '../../store/account/account.slice';
import commonStyle from '../../styles/commonStyle';

const ViewAccounts: React.FC<any> = ({
  intl,
  loader,
  paginationModel,
  setPaginationModel,
}): ReactElement => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ability = useSelector((state: any) => state.auth.ability);
  const accounts = useSelector((state: any) => state.account.accounts);
  const totalAccounts = useSelector((state: any) => state.account.totalAccounts);
  const [snackbarValues, setSnackBarValues] = useState<ISnackBar>({} as ISnackBar);
  const [rows, setRows] = useState<any[]>(accounts);

  useEffect(() => setRows(accounts), [accounts]);

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerAlign: 'left',
      align: 'left',
      headerName: intl.formatMessage({ id: 'account_name' }),
      flex: 1.5,
      renderCell: (params: any) => {
        return (
          <strong>
            <Button
              style={{
                marginLeft: 'auto',
                marginRight: 'auto',
                textTransform: 'none',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
              tabIndex={params.hasFocus ? 0 : -1}
              onClick={() => openDetailsPage(params)}>
              {params.value}
            </Button>
          </strong>
        );
      },
    },
    {
      field: 'ownership',
      headerAlign: 'left',
      align: 'left',
      headerName: intl.formatMessage({ id: 'ownership' }),
      flex: 1.3,
      renderCell: (params: any) => (
        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {params.row.ownership}
        </div>
      ),
    },
    {
      field: 'owner_name',
      headerAlign: 'left',
      align: 'left',
      headerName: intl.formatMessage({ id: 'owner' }),
      flex: 1.5,
      renderCell: (params: any) => (
        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {params.row.owner_name}
        </div>
      ),
    },
    {
      field: 'industry_type_name',
      headerAlign: 'left',
      align: 'left',
      headerName: intl.formatMessage({ id: 'industryType' }),
      flex: 1,
      renderCell: (params: any) => (
        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {params.row.industry_type_name}
        </div>
      ),
    },
    {
      field: 'created_on',
      headerAlign: 'left',
      align: 'left',
      headerName: intl.formatMessage({ id: 'createdOn' }),
      flex: 1,
      renderCell: (params: any) => {
        return dayjs(params.row.created_on)
          .format(localStorage.getItem('dateFormat') ?? defaultDateFormat)
          .toString();
      },
    },
    {
      field: 'actions',
      headerName: '',
      align: 'right',
      flex: 0.5,
      renderCell: (params: any) => {
        return [
          <GridActionsCellItem
            key={params.row.id + params.row.name}
            icon={<EditIcon />}
            label="Edit"
            onClick={() => onEditAccount(params)}
          />,
        ];
      },
    },
  ];

  const onEditAccount = (data: any) => {
    if (ability.can('PUT', 'Account')) {
      dispatch(setEditData(data.row));
      dispatch(setEditMode(true));
      dispatch(setDisabledMode(false));
      dispatch(setDetailedMode(false));
      localStorage.setItem('AccountInfo', JSON.stringify(data.row));
      localStorage.setItem('AccountDetailedMode', 'false');
      navigate(`/account/edit/${data.row.id}`);
    } else
      setSnackBarValues({
        display: true,
        type: 'error',
        message: intl.formatMessage({ id: 'notAllowedMessage' }),
      });
  };

  const openDetailsPage = (data: any) => {
    if (ability.can('PUT', 'Account')) {
      dispatch(setEditData(data.row));
      dispatch(setEditMode(true));
      dispatch(setDisabledMode(true));
      dispatch(setDetailedMode(true));
      localStorage.setItem('AccountInfo', JSON.stringify(data.row));
      localStorage.setItem('AccountDetailedMode', 'true');
      navigate(`/account/edit/${data.row.id}`);
    } else
      setSnackBarValues({
        display: true,
        type: 'error',
        message: intl.formatMessage({ id: 'notAllowedMessage' }),
      });
  };

  const handlePaginationModelChange = (newModel: { page: number; pageSize: number }) => {
    setPaginationModel(newModel);
  };

  return (
    <>
      <br />
      <Box sx={{ height: '520px', width: '100%' }}>
        <DataGrid
          // components={{ Toolbar: QuickSearchToolbar }}
          rows={loader ? [] : rows}
          columns={columns}
          density="standard"
          loading={loader}
          sx={commonStyle.dateGridStyle}
          pageSizeOptions={[20, 30]}
          disableRowSelectionOnClick
          disableColumnMenu
          rowCount={totalAccounts}
          pagination
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 20,
                page: 0,
              },
            },
            columns: {
              columnVisibilityModel: {
                actions: true,
              },
            },
          }}
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={handlePaginationModelChange}
        />
      </Box>
      {snackbarValues.message ? (
        <Snackbar
          display={snackbarValues.display}
          type={snackbarValues.type}
          message={snackbarValues.message}
          onClose={() => setSnackBarValues({ display: false } as ISnackBar)}
        />
      ) : null}
    </>
  );
};
export default injectIntl(ViewAccounts);
