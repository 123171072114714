import { Box, TextField } from '@mui/material';
import React from 'react';

export interface ISearchBox {
  value: string;
  setValue: any;
  label: string;
  variant: 'standard' | 'filled' | 'outlined';
  id: string;
}

const SearchBox: React.FC<ISearchBox> = ({ value, label, variant = 'outlined', id, setValue }) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  return (
    <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off">
      <TextField id={id} label={label} variant={variant} value={value} onChange={handleChange} />
    </Box>
  );
};

export default SearchBox;
