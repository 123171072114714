import axiosInstance from '../axiosInstance';

export const PATHS = Object.freeze({
  TENANT: `/api/v1/tenant`,
  TENANT_deal_terms: `/api/v1/tenant/deal_terms`,
  deal_terms_options: (id: string) => `/api/v1/tenant/deal_terms/${id}/deal_term_option`,
  TENANT_USERS: (id: string) => `/api/v1/tenant/${id}/users`,
});

interface GetTenantsOptions {
  offset?: number;
  limit?: number;
}

const getTenants = async (options: GetTenantsOptions): Promise<any> => {
  const { offset, limit } = options;
  let url = `${PATHS.TENANT}`;
  if (limit) {
    url = `${PATHS.TENANT}?offset=${offset}&limit=${limit}`;
  }

  const response = await axiosInstance.get(url);
  return response.data;
};

interface DealTermsOptions {
  key: string;
  label: string;
  type: string;
  id?: string | null;
}

const dealTerms = async (options: DealTermsOptions): Promise<any> => {
  const response = await axiosInstance.post(`${PATHS.TENANT_deal_terms}`, options);
  return response.data;
};

interface AddDealTermsOptions {
  id: string;
  label: string;
  value: string;
}

const addDealTermsOptions = async (data: AddDealTermsOptions): Promise<any> => {
  const d = {
    label: data.label,
    value: data.value,
  };
  const response = await axiosInstance.put(PATHS.deal_terms_options(data.id), d);
  return response.data;
};

const addTenant = async (data: any): Promise<any> => {
  const response = await axiosInstance.post(`${PATHS.TENANT}`, data);
  return response.data;
};

const updateTenant = async (tenantId: string, data: any): Promise<any> => {
  const response = await axiosInstance.patch(`${PATHS.TENANT}/${tenantId}`, data);
  return response.data;
};

const deleteTenant = async (tenantId: string): Promise<any> => {
  const response = await axiosInstance.delete(`${PATHS.TENANT}/${tenantId}`);
  return response.data;
};

const deleteDealTerm = async (id: string): Promise<any> => {
  const response = await axiosInstance.delete(`${PATHS.TENANT_deal_terms}/${id}`);
  return response.data;
};

const getDealTerm = async (id: string): Promise<any> => {
  const response = await axiosInstance.get(`${PATHS.TENANT_deal_terms}/${id}`);
  return response.data;
};

const getTenantUsers = async (tenantId: string): Promise<any> => {
  const response = await axiosInstance.get(`${PATHS.TENANT_USERS(tenantId)}`);
  return response.data;
};

const getTenantMonitor = async (): Promise<any> => {
  const response = await axiosInstance.get(`${PATHS.TENANT}/monitor`);
  return response.data;
};

interface GetDealTermsOptions {
  offset?: number;
  limit?: number;
  searchParam?: string | undefined;
  selectedFilter?: any;
}

const getDealTerms = async (options: GetDealTermsOptions): Promise<any> => {
  const { offset, limit, searchParam, selectedFilter } = options;
  let filter;
  if (selectedFilter) {
    filter = selectedFilter.label;
  } else {
    filter = null;
  }

  let url = `${PATHS.TENANT_deal_terms}`;
  if (limit && searchParam) {
    url = `${PATHS.TENANT_deal_terms}?offset=${offset}&limit=${limit}&search=${searchParam}&filtered=${filter}`;
  } else if (!limit && searchParam) {
    url = `${PATHS.TENANT_deal_terms}?search=${searchParam}&filtered=${filter}`;
  } else if (limit && !searchParam) {
    url = `${PATHS.TENANT_deal_terms}?offset=${offset}&limit=${limit}&filtered=${filter}`;
  } else {
    url = `${PATHS.TENANT_deal_terms}`;
  }
  const response = await axiosInstance.get(url);
  return response.data;
};

const addDealTerms = async (data: DealTermsOptions): Promise<any> => {
  const response = await axiosInstance.post(`${PATHS.TENANT_deal_terms}`, data);
  return response.data;
};

const editDealTerms = async (data: DealTermsOptions): Promise<any> => {
  const response = await axiosInstance.patch(`${PATHS.TENANT_deal_terms}/${data.id}`, data);
  return response.data;
};

interface EnableDealTermsOptions {
  id: string;
  isEnabled: boolean;
}

const enableDealTerms = async (data: EnableDealTermsOptions): Promise<any> => {
  const response = await axiosInstance.patch(`${PATHS.TENANT_deal_terms}/${data.id}`, {
    is_enabled: data.isEnabled,
  });
  return response.data;
};

interface EditDealTermsOptions {
  dealTermId: string;
  dealTermOptionId: string;
  label?: string;
  value?: string;
}
const editDealTermsOption = async (data: EditDealTermsOptions): Promise<any> => {
  const { dealTermId, dealTermOptionId, label, value } = data;
  const d = {
    label,
    value,
  };
  const response = await axiosInstance.patch(
    `${PATHS.TENANT_deal_terms}/${dealTermId}/deal_term_option/${dealTermOptionId}`,
    d,
  );
  return response.data;
};

interface EditIsDefaultOption {
  dealTermId: string;
  dealTermOptionId: string;
  is_default: boolean;
}

const editIsDefaultOption = async (data: EditIsDefaultOption): Promise<any> => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { dealTermId, dealTermOptionId, is_default } = data;

  const response = await axiosInstance.patch(
    `${PATHS.TENANT_deal_terms}/${dealTermId}/deal_term_option/${dealTermOptionId}`,
    { is_default },
  );
  return response.data;
};

interface DisableDealTermsOptions {
  dealTermId: string;
  dealTermOptionId: string;
  isActive: boolean;
}

const disableDealTermsOption = async (data: DisableDealTermsOptions): Promise<any> => {
  const { dealTermId, dealTermOptionId, isActive } = data;

  const response = await axiosInstance.patch(
    `${PATHS.TENANT_deal_terms}/${dealTermId}/deal_term_option/${dealTermOptionId}`,
    { is_active: isActive },
  );
  return response.data;
};
const deleteDealTermsOption = async (data: EditDealTermsOptions): Promise<any> => {
  const { dealTermId, dealTermOptionId } = data;
  const response = await axiosInstance.delete(
    `${PATHS.TENANT_deal_terms}/${dealTermId}/deal_term_option/${dealTermOptionId}`,
  );
  return response.data;
};

export interface TenantAPIClient {
  readonly getTenants: (options: GetTenantsOptions) => Promise<any>;
  readonly addDealTermsOptions: (data: AddDealTermsOptions) => Promise<any>;
  readonly addTenant: (data: any) => Promise<any>;
  readonly dealTerms: (options: any) => Promise<any>;
  readonly updateTenant: (userId: string, data: any) => Promise<any>;
  readonly deleteTenant: (userId: string) => Promise<any>;
  readonly deleteDealTerm: (id: string) => Promise<any>;
  readonly getDealTerm: (id: string) => Promise<any>;
  readonly getTenantUsers: (tenantId: string) => Promise<any>;
  readonly getTenantMonitor: () => Promise<any>;
  readonly getDealTerms: (options: GetDealTermsOptions) => Promise<any>;
  readonly addDealTerms: (data: DealTermsOptions) => Promise<any>;
  readonly editDealTerms: (data: DealTermsOptions) => Promise<any>;
  readonly enableDealTerms: (data: EnableDealTermsOptions) => Promise<any>;
  readonly editDealTermsOption: (data: EditDealTermsOptions) => Promise<any>;
  readonly editIsDefaultOption: (data: EditIsDefaultOption) => Promise<any>;
  readonly deleteDealTermsOption: (data: EditDealTermsOptions) => Promise<any>;
  readonly disableDealTermsOption: (data: DisableDealTermsOptions) => Promise<any>;
}

const TenantsClient: TenantAPIClient = Object.freeze({
  getTenants,
  editIsDefaultOption,
  addDealTermsOptions,
  addTenant,
  dealTerms,
  updateTenant,
  deleteTenant,
  deleteDealTerm,
  getDealTerm,
  getTenantUsers,
  getTenantMonitor,
  getDealTerms,
  addDealTerms,
  editDealTerms,
  enableDealTerms,
  editDealTermsOption,
  deleteDealTermsOption,
  disableDealTermsOption,
});

export default TenantsClient;
