import currencyList from '../constants/currencyArray';

const formatMap = new Map<string, Intl.NumberFormat>();

const currencyLocaleMap = new Map<string, string>([
  ['USD', 'en-US'],
  ['EUR', 'de-DE'],
  ['JPY', 'ja-JP'],
]);
export function formatCurrency(value: number, currency: string = 'USD'): string {
  let formatter: Intl.NumberFormat = new Intl.NumberFormat(); // Initialize formatter with a default value
  if (!formatMap.has(currency)) {
    let locale = currencyLocaleMap.get(currency);
    if (!locale) {
      locale = Intl.NumberFormat().resolvedOptions().locale;
    }
    formatter = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
    });
    formatMap.set(currency, formatter);
  }
  formatter = formatMap.get(currency) as Intl.NumberFormat;

  const toReturn = formatter.format(value);
  return toReturn;
}

export const isCurrencyCodeExists = (item: any) => {
  const curr = currencyList.find((singleCurrency) => singleCurrency.code === item);
  if (curr !== undefined) {
    return curr.symbol;
  }
  return undefined;
};

export function formatNumber(value: number): string {
  return new Intl.NumberFormat().format(value);
}
