import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import React, { ReactElement, useCallback, useEffect, useState, useRef } from 'react';
import { injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import Snackbar from '../../components/Snackbar/Snackbar';
import { ISnackBar } from '../../models/common';
import {
  setDetailedMode,
  setDisabledMode,
  setEditMode,
  setOpportunities,
  setOpportunitiesTotalCount,
  setRefetchData,
} from '../../store/opportunity/opportunity.slice';
import { setSection } from '../../store/user_sections/userSections.slice';
import commonStyle from '../../styles/commonStyle';
import getColumns from './OpportunityConstants';
import OpportunityClient from '../../api/Opportunity/OpportunityAPI';
import ConfirmDialog from '../../components/DialogBox/ConfirmDialog';

const ViewOpportunities: React.FC<any> = ({
  intl,
  loader,
  searchTerm,
  setIsDataLoading,
}): ReactElement => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ability = useSelector((state: any) => state.auth.ability);
  const opportunities = useSelector((state: any) => state.opportunity.opportunities);
  const totalOpportunities = useSelector((state: any) => state.opportunity.totalOpportunities);
  const [snackbarValues, setSnackBarValues] = useState<ISnackBar>({} as ISnackBar);
  const [rows, setRows] = useState<any[]>(opportunities);
  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [deleteProdId, setDeleteProdId] = useState('');
  const [deleteProdName, setDeleteProdName] = useState('');
  const [paginationModel, setPaginationModel] = useState<{
    page: number;
    pageSize: number;
  }>({
    page: 0,
    pageSize: 20,
  });
  const [offset, setOffset] = useState<number>(paginationModel.page * paginationModel.pageSize);
  const [limit, setLimit] = useState<number>(paginationModel.pageSize);
  const offsetRef = useRef(offset);
  const limitRef = useRef(limit);
  const editAccountData = useSelector((state: any) => state.account.editAccountData);

  useEffect(() => {
    offsetRef.current = offset;
    limitRef.current = limit;
  }, [offset, limit]);
  useEffect(() => setRows(opportunities), [opportunities]);
  useEffect(() => {
    setOffset(paginationModel.page * paginationModel.pageSize);
    setLimit(paginationModel.pageSize);
  }, [paginationModel]);
  const onEditOpportunity = (data: any) => {
    if (ability.can('PUT', 'Opportunity')) {
      dispatch(setEditMode(true));
      dispatch(setDisabledMode(false));
      dispatch(setDetailedMode(false));
      localStorage.setItem('OpportunityDetailedMode', 'false');
      navigate(`/opportunity/edit/${data.row.id}`);
    } else
      setSnackBarValues({
        display: true,
        type: 'error',
        message: intl.formatMessage({ id: 'notAllowedMessage' }),
      });
  };
  const openDetailsPage = (data: any) => {
    if (ability.can('PUT', 'Opportunity')) {
      dispatch(setEditMode(true));
      dispatch(setDisabledMode(true));
      dispatch(setDetailedMode(true));
      localStorage.setItem('OpportunityDetailedMode', 'true');
      dispatch(
        setSection({
          id: 15,
          name: 'opportunity',
          route: '/opportunity',
        }),
      );
      navigate(`/opportunity/edit/${data.row.id}`);
    } else
      setSnackBarValues({
        display: true,
        type: 'error',
        message: intl.formatMessage({ id: 'notAllowedMessage' }),
      });
  };

  const openDeleteModal = (val: any): any => {
    setDeleteProdId(val.id);
    setDeleteProdName(val.row.name);
    setShowDeleteConfirmDialog(true);
  };
  const onDeleteProduct = () => {
    setDeleteLoader(true);

    OpportunityClient.deleteOpportunity(deleteProdId)
      .then(() => {
        dispatch(setRefetchData(true));
        setShowDeleteConfirmDialog(false);
        setDeleteLoader(false);
        setSnackBarValues({
          display: true,
          type: 'success',
          message: intl.formatMessage({ id: 'deleteOpportunitySuccess' }),
        });
      })
      .catch((err: any) => {
        setDeleteLoader(false);
        setShowDeleteConfirmDialog(false);
        console.error(err);
      });
  };

  const fetchSearchedResults = async (query?: string): Promise<void> => {
    const currentOffset = offsetRef.current;
    const currentLimit = limitRef.current;
    setIsDataLoading(true);
    let responsee;
    if (query) {
      const data = {
        searchParam: query,
        offset: currentOffset,
        limit: currentLimit,
      };
      responsee = OpportunityClient.getOpportunity(data);
    } else if (
      Object.keys(editAccountData).length !== 0 &&
      window.location.pathname !== '/opportunity'
    ) {
      const data = {
        id: editAccountData.id,
        offset,
        limit,
      };
      responsee = OpportunityClient.getOpportunitiesByAccountId(data);
    } else {
      const data = {
        offset,
        limit,
      };
      responsee = OpportunityClient.getOpportunity(data);
    }

    responsee
      .then((response: any) => {
        dispatch(setOpportunities(response.data));
        dispatch(setOpportunitiesTotalCount(response.total_items));
        dispatch(setRefetchData(false));
        setIsDataLoading(false);
      })
      .catch((e) => {
        setIsDataLoading(false);
        console.error(e);
      });
  };

  const debouncedFetchSearchResults = useCallback(
    _.debounce((query: string) => fetchSearchedResults(query), 500),
    [],
  );

  useEffect(() => {
    if (searchTerm) {
      debouncedFetchSearchResults(searchTerm);
    } else {
      fetchSearchedResults();

      debouncedFetchSearchResults.cancel();
    }
  }, [searchTerm, debouncedFetchSearchResults, offset, limit]);
  const handlePaginationModelChange = (newModel: { page: number; pageSize: number }) => {
    setPaginationModel(newModel);
  };

  useEffect(() => {
    setPaginationModel((prevModel) => ({ ...prevModel, page: 0 }));
  }, [searchTerm]);

  return (
    <>
      <br />
      <Box sx={{ width: '100%', height: '550px' }}>
        {window.location.pathname === '/opportunity' ? (
          <DataGrid
            rows={loader ? [] : rows}
            columns={getColumns(onEditOpportunity, openDetailsPage, openDeleteModal)}
            rowCount={totalOpportunities}
            loading={loader}
            pageSizeOptions={[20, 30]}
            pagination
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 20,
                  page: 0,
                },
              },
            }}
            paginationMode="server"
            paginationModel={paginationModel}
            onPaginationModelChange={handlePaginationModelChange}
            sx={commonStyle.dateGridStyle}
            disableRowSelectionOnClick
            disableColumnMenu
          />
        ) : (
          <DataGrid
            rows={loader ? [] : opportunities}
            columns={getColumns(onEditOpportunity, openDetailsPage, openDeleteModal)}
            rowCount={totalOpportunities}
            loading={loader}
            pageSizeOptions={[20, 30]}
            pagination
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 20,
                  page: 1,
                },
              },
              columns: {
                columnVisibilityModel: {
                  actions: false,
                },
              },
            }}
            paginationMode="server"
            paginationModel={paginationModel}
            onPaginationModelChange={handlePaginationModelChange}
            // onPageChange={(newPage: number) => setPage(newPage)}
            // onPageSizeChange={(newPageSize: number) => setPageSize(newPageSize)}
            sx={commonStyle.dateGridStyle}
            disableRowSelectionOnClick
            disableColumnMenu
            density="standard"
          />
        )}
        {showDeleteConfirmDialog ? (
          <ConfirmDialog
            dialogConfig={{
              name: 'confirmDelete',
              text: 'confirmMessageWithName',
              open: showDeleteConfirmDialog,
              productName: deleteProdName,
              loader: deleteLoader,
              handleConfirm: onDeleteProduct,
              handleCancel: () => {
                setDeleteProdId('');
                setDeleteProdName('');
                setShowDeleteConfirmDialog(false);
              },
            }}
          />
        ) : null}
      </Box>
      {snackbarValues.message ? (
        <Snackbar
          display={snackbarValues.display}
          type={snackbarValues.type}
          message={snackbarValues.message}
          onClose={() => setSnackBarValues({ display: false } as ISnackBar)}
        />
      ) : null}
    </>
  );
};
export default injectIntl(ViewOpportunities);
