import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';
import SearchAddAutocomplete from '../../components/Autocomplete/SearchAddAutocomplete';
import styles from '../../styles/styles';
import Snackbar from '../../components/Snackbar/Snackbar';
import { ISnackBar } from '../../models/common';
import PriceBookRuleClient from '../../api/PriceBookRules/PricebookRulesAPI';

import {
  setEditData,
  setEditMode,
  setRefetchData,
} from '../../store/price_book_rules/pricebookRules.slice';

const CreatePricebook: React.FC<any> = ({
  intl,
  isPBRDialogOpen,
  setPBRDialogOpen,
}): ReactElement => {
  const dispatch = useDispatch();
  const priceBooks = useSelector((state: any) => state.createPriceBookRules.priceBooks);
  const designations = useSelector((state: any) => state.createPriceBookRules.designations);
  const users = useSelector((state: any) => state.createPriceBookRules.users);
  const oppTypes = useSelector((state: any) => state.createPriceBookRules.oppTypes);
  const isEditMode = useSelector((state: any) => state.priceBookRules.isEditMode);
  const editPriceBookRuleData = useSelector(
    (state: any) => state.priceBookRules.editPriceBookRuleData,
  );
  const [snackbarValues, setSnackBarValues] = useState<ISnackBar>({} as ISnackBar);

  const [selectedPriceBooks, setSelectedPriceBooks] = useState<any>([]);
  const [selectedDesignations, setSelectedDesignations] = useState<any>([]);
  const [selectedOppTypes, setSelectedOppTypes] = useState<any>([]);
  const [selectedUsers, setSelectedUsers] = useState<any>([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  useEffect(() => {
    if (isEditMode && priceBooks.length && (users.length || designations.length)) {
      setSelectedPriceBooks([
        ...selectedPriceBooks,
        priceBooks.find((pb: any) => pb.id === editPriceBookRuleData.price_book_id),
      ]);
      if (editPriceBookRuleData.user_id)
        setSelectedUsers([
          ...selectedUsers,
          users.find((u: any) => u.id === editPriceBookRuleData.user_id),
        ]);
      if (editPriceBookRuleData.org_hierarchy_id)
        setSelectedDesignations([
          ...selectedDesignations,
          designations.find((u: any) => u.id === editPriceBookRuleData.org_hierarchy_id),
        ]);
      if (editPriceBookRuleData.opportunity_type_id)
        setSelectedOppTypes([
          ...selectedOppTypes,
          oppTypes.find((u: any) => u.id === editPriceBookRuleData.opportunity_type_id),
        ]);
      openDialog();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditMode, priceBooks, users, designations, oppTypes]);

  useEffect(() => {
    if (isEditMode) {
      if (
        editPriceBookRuleData.price_book_id ===
          (selectedPriceBooks.length > 0 ? selectedPriceBooks[0].id : null) &&
        editPriceBookRuleData.opportunity_type_id ===
          (selectedOppTypes.length > 0 ? selectedOppTypes[0].id : null) &&
        editPriceBookRuleData.org_hierarchy_id ===
          (selectedDesignations.length > 0 ? selectedDesignations[0].id : null) &&
        editPriceBookRuleData.user_id === (selectedUsers.length > 0 ? selectedUsers[0].id : null)
      ) {
        setIsSaveDisabled(true);
      } else {
        setIsSaveDisabled(false);
      }
    }
  }, [
    isEditMode,
    editPriceBookRuleData,
    selectedOppTypes,
    selectedUsers,
    selectedPriceBooks,
    selectedDesignations,
  ]);

  const openDialog = () => {
    setPBRDialogOpen(true);
  };
  const handleClose = () => {
    setPBRDialogOpen(false);
    setSelectedPriceBooks([]);
    setSelectedDesignations([]);
    setSelectedOppTypes([]);
    setSelectedUsers([]);
    dispatch(setEditMode(false));
    dispatch(setEditData(false));
  };

  const isValidData = () => {
    let isValid = true;
    if (selectedPriceBooks && selectedPriceBooks.length < 1) {
      setSnackBarValues({
        display: true,
        type: 'error',
        message: intl.formatMessage({ id: 'pleaseSelectPricebook' }),
      });
      isValid = false;
      return false;
    }
    if (selectedDesignations.length < 1 && selectedUsers.length < 1) {
      setSnackBarValues({
        display: true,
        type: 'error',
        message: intl.formatMessage({ id: 'pleaseSelectDesignationOrUser' }),
      });
      isValid = false;
      return false;
    }

    return isValid;
  };

  const handleSave = async () => {
    if (isValidData()) {
      try {
        setIsSaving(true);
        const apiCalls: any = [];
        const oppTypeIds = selectedOppTypes.map((book: any) => book.id);
        const priceBookIds = selectedPriceBooks.map((book: any) => book.id);
        const orgHierarchyIds = selectedDesignations.map((book: any) => book.id);
        const userIds = selectedUsers.map((book: any) => book.id);
        const uniqueObjects = new Set<string>();
        const oppTypeIdList = oppTypeIds.length === 0 ? [null] : oppTypeIds;
        const userIdList = userIds.length === 0 ? [null] : userIds;
        const orgHierarchyIdList = orgHierarchyIds.length === 0 ? [null] : orgHierarchyIds;
        oppTypeIdList.forEach((oppTypeId: any) => {
          userIdList.forEach((userId: any) => {
            orgHierarchyIdList.forEach((orgHierarchyId: any) => {
              priceBookIds.forEach((priceBookId: any) => {
                const createObject = (customOb: any) => {
                  const obStr = JSON.stringify(customOb);
                  if (!uniqueObjects.has(obStr)) {
                    uniqueObjects.add(obStr);
                    apiCalls.push(customOb);
                  }
                };

                if (userIds.length > 0 && orgHierarchyIds.length > 0) {
                  const ob1 = {
                    price_book_id: priceBookId,
                    opportunity_type_id: oppTypeId,
                    user_id: null,
                    org_hierarchy_id: orgHierarchyId,
                  };
                  createObject(ob1);

                  const ob2 = {
                    price_book_id: priceBookId,
                    opportunity_type_id: oppTypeId,
                    user_id: userId,
                    org_hierarchy_id: null,
                  };
                  createObject(ob2);
                } else {
                  const ob = {
                    price_book_id: priceBookId,
                    opportunity_type_id: oppTypeId,
                    user_id: userId,
                    org_hierarchy_id: orgHierarchyId,
                  };
                  createObject(ob);
                }
              });
            });
          });
        });

        hitCreatePriceBookApi(apiCalls);
      } catch (e) {
        console.error(e);
        setIsSaving(false);
      }
    }
  };

  async function hitCreatePriceBookApi(apiCalls: any) {
    try {
      if (!isEditMode) {
        const response = await PriceBookRuleClient.createPriceBookRule(apiCalls);
        if (response.message === 'success') {
          setSnackBarValues({
            display: true,
            type: 'success',
            message: intl.formatMessage({ id: 'pricebookRuleCreated' }),
          });
          setTimeout(() => {
            handleClose();
            dispatch(setRefetchData(true));
            dispatch(setEditMode(false));
            dispatch(setEditData(false));
            setIsSaving(false);
          }, 1000);
        }
      } else {
        const response = await PriceBookRuleClient.editPriceBookRule(
          editPriceBookRuleData.id,
          apiCalls,
        );
        if (response.message === 'success') {
          setSnackBarValues({
            display: true,
            type: 'success',
            message: intl.formatMessage({ id: 'pricebookRuleUpdated' }),
          });
          setTimeout(() => {
            handleClose();
            dispatch(setRefetchData(true));
            dispatch(setEditMode(false));
            dispatch(setEditData(false));
            setIsSaving(false);
          }, 1000);
        }
      }
    } catch (error) {
      console.log(error, 'errorerror');
      setIsSaving(false);
    }
  }
  const PricebookRuleRow = useMemo((): ReactElement => {
    return (
      <Stack direction="row">
        <FormControl sx={{ margin: '20px 4px', width: '280px' }}>
          <SearchAddAutocomplete
            caption="pricebook"
            data={priceBooks}
            selectedItem={selectedPriceBooks}
            setSelectedData={(value: any) => {
              if (isEditMode) {
                const lastItem = value[value.length - 1];
                setSelectedPriceBooks(() => (lastItem === undefined ? [] : [lastItem]));
              } else {
                setSelectedPriceBooks(() => [...value]);
              }
            }}
            showAddOption={false}
            showSelectionValue
            multiselect
          />
        </FormControl>
        <FormControl sx={{ margin: '20px 4px', width: '280px' }}>
          <SearchAddAutocomplete
            caption="designation"
            // eslint-disable-next-line no-nested-ternary
            data={isEditMode ? (selectedUsers.length === 0 ? designations : []) : designations}
            selectedItem={selectedDesignations}
            setSelectedData={(value: any) => {
              if (isEditMode) {
                const lastItem = value[value.length - 1];
                setSelectedDesignations(() => (lastItem === undefined ? [] : [lastItem]));
              } else {
                setSelectedDesignations(() => [...value]);
              }
            }}
            showAddOption={false}
            showSelectionValue
            multiselect
          />
        </FormControl>
        <FormControl sx={{ margin: '20px 4px', width: '280px' }}>
          <SearchAddAutocomplete
            caption="user"
            // eslint-disable-next-line no-nested-ternary
            data={isEditMode ? (selectedDesignations.length === 0 ? users : []) : users}
            selectedItem={selectedUsers}
            setSelectedData={(value: any) => {
              if (isEditMode) {
                const lastItem = value[value.length - 1];
                setSelectedUsers(() => (lastItem === undefined ? [] : [lastItem]));
              } else {
                setSelectedUsers(() => [...value]);
              }
            }}
            multiselect
            showAddOption={false}
            showSelectionValue
          />
        </FormControl>
        <FormControl sx={{ margin: '20px 4px', width: '280px' }}>
          <SearchAddAutocomplete
            caption="opportunityType"
            data={oppTypes}
            selectedItem={selectedOppTypes}
            setSelectedData={(value: any) => {
              if (isEditMode) {
                const lastItem = value[value.length - 1];
                setSelectedOppTypes(() => (lastItem === undefined ? [] : [lastItem]));
              } else {
                setSelectedOppTypes(() => [...value]);
              }
            }}
            showAddOption={false}
            showSelectionValue
            multiselect
          />
        </FormControl>
      </Stack>
    );
    // eslint-disable-next-line
  }, [
    priceBooks,
    selectedPriceBooks,
    selectedOppTypes,
    selectedDesignations,
    selectedUsers,
    designations,
    users,
    oppTypes,
  ]);

  return (
    <>
      <Button sx={styles.dialogButton} onClick={openDialog}>
        <FormattedMessage id="createPricebookRule" />
      </Button>
      <Dialog open={isPBRDialogOpen} maxWidth="lg">
        <DialogTitle>
          <FormattedMessage id={isEditMode ? 'editPriceBookRule' : 'createPricebookRule'} />
        </DialogTitle>
        <DialogContent dividers>
          <Grid>
            <Stack>
              <Grid item md={12}>
                <Stack direction="row">
                  <Typography width="290px" className="dialogHeading">
                    <FormattedMessage id="pricebook" />
                  </Typography>
                  <Typography width="335px" className="dialogHeading">
                    <FormattedMessage id="designation" />
                  </Typography>
                  <Typography width="290px" className="dialogHeading">
                    <FormattedMessage id="user" />
                  </Typography>
                  <Typography width="290px" className="dialogHeading">
                    <FormattedMessage id="opportunityType" />
                  </Typography>
                </Stack>
                <Divider />
                {PricebookRuleRow}
              </Grid>
            </Stack>
          </Grid>
          <DialogActions>
            <Button sx={styles.dialogButton} onClick={handleClose}>
              <FormattedMessage id="cancel" />
            </Button>
            <Button sx={styles.dialogButton} onClick={() => handleSave()} disabled={isSaveDisabled}>
              {isSaving ? (
                <CircularProgress color="inherit" size={20} />
              ) : (
                <FormattedMessage id="save" />
              )}
            </Button>
          </DialogActions>
          {isEditMode && (
            <Typography sx={{ color: 'red', fontSize: '18px' }}>
              Max 1 selection is allowed in each. Designation and User both aren&apos;t allowed
              together.
            </Typography>
          )}
        </DialogContent>
      </Dialog>
      {snackbarValues.message ? (
        <Snackbar
          display={snackbarValues.display}
          type={snackbarValues.type}
          message={snackbarValues.message}
          onClose={() => setSnackBarValues({ display: false } as ISnackBar)}
        />
      ) : null}
    </>
  );
};
export default injectIntl(CreatePricebook);
