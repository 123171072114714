import { Box, CircularProgress } from '@mui/material';
import React, { ReactElement } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import { injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { ReactComponent as SaveIcon } from '../../assets/icons/save.svg';

const componentStyle = {
  mainContainer: {
    height: '48px',
    background: '#5D5FEF',
    color: 'white',
    minWidth: '150px',
    borderRadius: '0px 10px 10px 0px',
    cursor: 'pointer',
    fontFamily: 'Helvetica',
    fontStyle: 'normal',
    fontWeight: 700,
    textAlign: 'center',
    verticalAlign: 'middle',
  },
  outlinedInput: {
    height: '48px',
    borderRadius: '10px 0px 0px 10px',
  },
  iconBoxContainer: {
    marginRight: '8px',
    marginTop: '1px',
    marginLeft: 'auto',
  },
};

interface IProps {
  placeholder: string;
  btnText: string;
  intl: any;
  value: any;
  showProgress: boolean;
  onTextChange: (value: any) => void;
  onClick: () => void;
  pricingCalculator?: boolean;
  setIsNewQuoteError?: any;
  id?: string;
  isErrorInCalculationApi?: boolean;
}

const SaveInput: React.FC<IProps> = ({
  intl,
  placeholder,
  btnText,
  value = '',
  showProgress,
  onTextChange,
  onClick,
  pricingCalculator,
  setIsNewQuoteError,
  id = 'save-input-box',
  isErrorInCalculationApi,
}): ReactElement => {
  const selectedOpportunity = useSelector(
    (state: any) => state.pricingCalculator.selectedOpportunity,
  );

  const handleSubmit = () => {
    if (pricingCalculator) {
      if (selectedOpportunity.id) {
        if (!isErrorInCalculationApi) {
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          value && onClick();
        }
      } else {
        setIsNewQuoteError(true);
      }
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      value && onClick();
    }
  };
  return (
    <Box
      sx={{
        display: 'flex',
      }}>
      <OutlinedInput
        onChange={onTextChange}
        value={value}
        sx={componentStyle.outlinedInput}
        placeholder={intl.formatMessage({ id: placeholder })}
        id={id}
      />
      <Box sx={componentStyle.mainContainer}>
        {showProgress ? (
          <CircularProgress size={24} sx={{ color: 'white', marginTop: '10px' }} />
        ) : (
          <Box
            onClick={handleSubmit}
            sx={{
              marginTop: '14px',
              display: 'flex',
              // eslint-disable-next-line no-nested-ternary
              opacity: pricingCalculator && isErrorInCalculationApi ? 0.5 : value ? 1 : 0.5,
              cursor:
                // eslint-disable-next-line no-nested-ternary
                pricingCalculator && isErrorInCalculationApi
                  ? 'not-allowed'
                  : value
                  ? 'pointer'
                  : 'not-allowed',
            }}>
            <Box sx={componentStyle.iconBoxContainer}>
              <SaveIcon />
            </Box>
            <Box sx={{ marginRight: 'auto' }}>{intl.formatMessage({ id: btnText })}</Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default injectIntl(SaveInput);
