import axiosInstance from '../axiosInstance';

export const PATHS = Object.freeze({
  PRICEBOOK: `/api/v1/pricebook`,
});

const getAllPriceBooks = async (): Promise<any> => {
  const response = await axiosInstance.get(PATHS.PRICEBOOK);
  return response.data;
};

interface GetPriceBooksOptions {
  opportunityId?: string | undefined;
  offset?: number;
  limit?: number;
}
const getPriceBooks = async (options: GetPriceBooksOptions = {}): Promise<any> => {
  const { opportunityId, offset, limit } = options;
  let url = `${PATHS.PRICEBOOK}`;
  if (limit && opportunityId) {
    url = `${PATHS.PRICEBOOK}?offset=${offset}&limit=${limit}&opportunity_id=${opportunityId}`;
  } else if (!limit && opportunityId) {
    url = `${PATHS.PRICEBOOK}?opportunity_id=${opportunityId}`;
  } else if (limit && !opportunityId) {
    url = `${PATHS.PRICEBOOK}?offset=${offset}&limit=${limit}`;
  } else {
    url = `${PATHS.PRICEBOOK}`;
  }
  const response = await axiosInstance.get(url);
  return response.data;
};

const getPriceBookById = async (id: string): Promise<any> => {
  const response = await axiosInstance.get(`${PATHS.PRICEBOOK}/${id}/details`);
  return response.data;
};

const createPriceBook = async (priceBook: any): Promise<any> => {
  const response = await axiosInstance.post(`${PATHS.PRICEBOOK}`, priceBook);
  return response.data;
};

const editPriceBook = async (pid: string, pbObject: any): Promise<any> => {
  const response = await axiosInstance.put(`${PATHS.PRICEBOOK}/${pid}`, pbObject);
  return response.data;
};

const deletePriceBook = async (pid: any): Promise<any> => {
  const response = await axiosInstance.delete(`${PATHS.PRICEBOOK}/${pid}`);
  return response;
};

const getDiscountPolicy = async (pid: string): Promise<any> => {
  const response = await axiosInstance.get(`${PATHS.PRICEBOOK}/${pid}/discount_policy`);
  return response.data;
};

const editHierarchy = async (pricebook_id: string, data: any): Promise<any> => {
  const response = await axiosInstance.patch(
    `${PATHS.PRICEBOOK}/${pricebook_id}/discount_policy/hierarchy`,
    data,
  );
  return response.data;
};

const createHierarchy = async (pricebook_id: string, data: any): Promise<any> => {
  const response = await axiosInstance.put(
    `${PATHS.PRICEBOOK}/${pricebook_id}/discount_policy/hierarchy`,
    data,
  );
  return response.data;
};

const deleteHierarchy = async (pricebook_id: string, delete_id: string): Promise<any> => {
  const response = await axiosInstance.delete(
    `${PATHS.PRICEBOOK}/${pricebook_id}/discount_policy/hierarchy?delete=${delete_id}`,
  );
  return response.data;
};

const updateDiscountingPolicy = async (pid: any, pbDiscountObject: any): Promise<any> => {
  const response = await axiosInstance.put(
    `${PATHS.PRICEBOOK}/${pid}/discount_policy/hierarchy`,
    pbDiscountObject,
  );
  return response.data;
};

interface CreateDealTermDiscountingPolicyOptions {
  pricebook_id: string;
  data: {
    price_book_id: string;
    tenant_id: string;
    deal_term_id: string;
    deal_term_option_id: string;
    value: string;
    discount: string;
  };
}

const createDealTermDiscountingPolicy = async (
  options: CreateDealTermDiscountingPolicyOptions,
): Promise<any> => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { pricebook_id, data } = options;

  const response = await axiosInstance.put(
    `${PATHS.PRICEBOOK}/${pricebook_id}/discount_policy/deal_term`,
    data,
  );
  return response.data;
};

const deleteDealTermDiscountingPolicy = async (
  pricebook_id: string,
  deal_term_id: string,
): Promise<any> => {
  const response = await axiosInstance.delete(
    `${PATHS.PRICEBOOK}/${pricebook_id}/discount_policy/deal_term/${deal_term_id}`,
  );
  return response.data;
};

interface EditDealTermDiscountingPolicyOptions {
  pricebook_id: string;
  deal_term_discount_id: string;

  data: {
    price_book_id: string;
    tenant_id: string;
    deal_term_id: string;
    deal_term_option_id: string;
    value: string | null | undefined;
    discount: number;
  };
}

const editDealTermDiscountingPolicy = async (
  options: EditDealTermDiscountingPolicyOptions,
): Promise<any> => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { pricebook_id, data, deal_term_discount_id } = options;
  const response = await axiosInstance.patch(
    `${PATHS.PRICEBOOK}/${pricebook_id}/discount_policy/deal_term/${deal_term_discount_id}`,
    data,
  );
  return response.data;
};

export interface PriceBookAPIClient {
  readonly getAllPriceBooks: (opportunityId?: string) => Promise<any>; //
  readonly getPriceBooks: (options: GetPriceBooksOptions) => Promise<any>;
  readonly createPriceBook: (priceBook: any) => Promise<any>;
  readonly editPriceBook: (pid: any, pbObject: any) => Promise<any>;
  readonly deletePriceBook: (pid: any) => Promise<any>;
  readonly getPriceBookById: (id: string) => Promise<any>;
  readonly getDiscountPolicy: (pid: string) => Promise<any>;
  readonly editHierarchy: (pricebook_id: string, data: any) => Promise<any>;
  readonly createHierarchy: (pricebook_id: string, data: any) => Promise<any>;
  readonly deleteHierarchy: (pricebook_id: string, delete_id: any) => Promise<any>;
  readonly updateDiscountingPolicy: (pid: any, pbDiscountObject: any) => Promise<any>;
  readonly createDealTermDiscountingPolicy: (
    options: CreateDealTermDiscountingPolicyOptions,
  ) => Promise<any>;
  readonly editDealTermDiscountingPolicy: (
    options: EditDealTermDiscountingPolicyOptions,
  ) => Promise<any>;
  readonly deleteDealTermDiscountingPolicy: (
    pricebook_id: string,
    deal_term_id: string,
  ) => Promise<any>;
}

const PriceBookClient: PriceBookAPIClient = Object.freeze({
  getAllPriceBooks,
  getPriceBooks,
  createPriceBook,
  editPriceBook,
  deletePriceBook,
  getPriceBookById,
  getDiscountPolicy,
  editHierarchy,
  createHierarchy,
  deleteHierarchy,
  updateDiscountingPolicy,
  createDealTermDiscountingPolicy,
  editDealTermDiscountingPolicy,
  deleteDealTermDiscountingPolicy,
});

export default PriceBookClient;
