import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button } from '@mui/material';
import { GridActionsCellItem, GridColDef } from '@mui/x-data-grid';
// import dayjs from 'dayjs';
// import { defaultDateFormat } from '../../constants/constants';

const getColumns = (
  onEditOpportunity: any,
  openDetailsPage: any,
  openDeleteModal: any,
): GridColDef[] => {
  return [
    {
      field: 'name',
      headerAlign: 'left',
      align: 'left',
      headerName: 'Opp Name',
      flex: 1.8,
      renderCell: (params: any) => {
        return (
          <strong>
            <Button
              style={{
                marginLeft: 'auto',
                marginRight: 'auto',
                textTransform: 'none',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
              tabIndex={params.hasFocus ? 0 : -1}
              onClick={() => {
                openDetailsPage(params);
              }}>
              {params.value}
            </Button>
          </strong>
        );
      },
    },
    {
      field: 'owner_name',
      headerAlign: 'left',
      align: 'left',
      headerName: 'AE',
      flex: 1.5,
      renderCell: (params: any) => (
        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {params.row.owner_name}
        </div>
      ),
    },
    // {
    //   field: 'close_date',
    //   headerAlign: 'left',
    //   align: 'left',
    //   headerName: 'Opp Closing Date',
    //   flex: 1,
    //   renderCell: (params: any) => {
    //     return dayjs(params.row.close_date)
    //       .format(localStorage.getItem('dateFormat') ?? defaultDateFormat)
    //       .toString();
    //   },
    // },
    {
      field: 'stage_name',
      headerAlign: 'left',
      align: 'left',
      headerName: 'Stage',
      flex: 1,
      renderCell: (params: any) => (
        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {params.row.stage_name}
        </div>
      ),
    },

    {
      field: 'actions',
      headerName: '',
      align: 'right',
      flex: 0.5,
      renderCell: (params: any) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            key={`${params.row.id}edit`}
            onClick={() => onEditOpportunity(params)}
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            key={`${params.row.id}delete`}
            onClick={() => openDeleteModal(params)}
          />,
        ];
      },
    },
  ];
};

export default getColumns;
