import { Box, Button, FormControl, Grid, Paper } from '@mui/material';
import React, { ReactElement, useState } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Snackbar from '../../components/Snackbar/Snackbar';
import { ISnackBar } from '../../models/common';
import ViewDealTerms from './ViewDealTerms';
import { setEditMode, setEditData } from '../../store/deal_terms/dealTerms.Slice';
import styles from '../../styles/styles';
import SearchBox from '../../components/searchBox/SearchBox';
import SearchAddAutocomplete from '../../components/Autocomplete/SearchAddAutocomplete';

const DealTerms = (): ReactElement => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [snackbarValues, setSnackBarValues] = useState<ISnackBar>({} as ISnackBar);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedFilter, setSelectedFilter] = useState<any>();

  const list = [
    { id: 2, label: 'active', name: 'Active' },
    { id: 3, label: 'inactive', name: 'InActive' },
  ];
  return (
    <>
      <Grid container>
        <Grid item md={12}>
          <Paper
            sx={
              window.location.pathname === '/deal-terms'
                ? {
                    padding: '18px 33px',
                    marginRight: '20px',
                  }
                : null
            }>
            {window.location.pathname === '/deal-terms' ? (
              <Box sx={styles.searchContainer}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                  <SearchBox
                    value={searchTerm}
                    setValue={setSearchTerm}
                    label="Search"
                    variant="outlined"
                    id="search-deal-term-page"
                  />
                  <FormControl sx={{ m: 1, width: 240 }}>
                    <SearchAddAutocomplete
                      selectedItem={selectedFilter}
                      caption="filterDealTerms"
                      data={list}
                      showSelectionValue
                      showAddOption={false}
                      setSelectedData={setSelectedFilter}
                      size="medium"
                    />
                  </FormControl>
                </Box>
                <Button
                  sx={styles.dialogButton}
                  onClick={() => {
                    dispatch(setEditMode(false));
                    dispatch(setEditData({}));
                    navigate('/deal-terms/add');
                  }}>
                  <FormattedMessage id="addDealTerm" />
                </Button>
              </Box>
            ) : null}
            <br />

            <ViewDealTerms searchTerm={searchTerm} selectedFilter={selectedFilter} />
          </Paper>
        </Grid>
      </Grid>

      {snackbarValues.message ? (
        <Snackbar
          display={snackbarValues.display}
          type={snackbarValues.type}
          message={snackbarValues.message}
          onClose={() => setSnackBarValues({ display: false } as ISnackBar)}
        />
      ) : null}
    </>
  );
};

export default injectIntl(DealTerms);
