import axiosInstance from '../axiosInstance';

export const PATHS = Object.freeze({
  ACCOUNT: `/api/v1/account`,
});

interface GetAccountsOptions {
  searchParam?: string | undefined;
  offset?: number;
  limit?: number;
}

const getAccounts = async (options: GetAccountsOptions): Promise<any> => {
  const { searchParam, offset, limit } = options;
  let url = `${PATHS.ACCOUNT}`;
  if (limit && searchParam) {
    url = `${PATHS.ACCOUNT}?offset=${offset}&limit=${limit}&search=${searchParam}`;
  } else if (!limit && searchParam) {
    url = `${PATHS.ACCOUNT}?search=${searchParam}`;
  } else if (limit && !searchParam) {
    url = `${PATHS.ACCOUNT}?offset=${offset}&limit=${limit}`;
  } else {
    url = `${PATHS.ACCOUNT}`;
  }

  const response = await axiosInstance.get(url);
  return response.data;
};

const createAccount = async (account: any): Promise<any> => {
  const response = await axiosInstance.post(`${PATHS.ACCOUNT}`, account);
  return response.data;
};

const editAccount = async (acId: any, account: any): Promise<any> => {
  const response = await axiosInstance.put(`${PATHS.ACCOUNT}/${acId}`, account);
  return response.data;
};
export interface AccountAPIClient {
  readonly getAccounts: (options: GetAccountsOptions) => Promise<any>;

  readonly createAccount: (account: any) => Promise<any>;
  readonly editAccount: (acId: number, account: any) => Promise<any>;
}

const AccountClient: AccountAPIClient = Object.freeze({
  getAccounts,
  createAccount,
  editAccount,
});

export default AccountClient;
