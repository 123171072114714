import React, { ReactElement, useEffect, useState } from 'react';
import './index.scss';
import { injectIntl } from 'react-intl';
import { Box, FormControl, Grid, Paper } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import {
  deletePriceBook,
  setEditData,
  setEditMode,
  setPriceBookRules,
  setTotalPriceBookRules,
  setRefetchData,
} from '../../store/price_book_rules/pricebookRules.slice';
import CreatePricebookRule from './CreatePricebookRule';
import PriceBookRuleClient from '../../api/PriceBookRules/PricebookRulesAPI';
import commonStyle from '../../styles/commonStyle';
import getColumns from './PricebookRuleConstants';
import SearchAddAutocomplete from '../../components/Autocomplete/SearchAddAutocomplete';
import PriceBookClient from '../../api/PriceBook/PricebookAPI';
import {
  setDesignations,
  setOppTypes,
  setPricebooks,
  setUsers,
} from '../../store/price_book_rules/createPriceBookRules.slice';
import ConfirmDialog from '../../components/DialogBox/ConfirmDialog';
import { ISnackBar } from '../../models/common';
import Snackbar from '../../components/Snackbar/Snackbar';

interface IProps {
  intl?: any;
}

const PricebookRule: React.FC<IProps> = ({ intl }): ReactElement => {
  const dispatch = useDispatch();
  const [isDataLoading, setIsDataLoading] = useState(false);
  const priceBookRules = useSelector((state: any) => state.priceBookRules.priceBookRules);
  const totalPriceBookRules = useSelector((state: any) => state.priceBookRules.totalPriceBookRules);
  const refetchData = useSelector((state: any) => state.priceBookRules.refetchData);
  const priceBooks = useSelector((state: any) => state.createPriceBookRules.priceBooks);
  const [selectedPriceBook, setSelectedPriceBook] = useState<any>('');
  const [showDeleteConfirmDialog, setShowDeleteConfirmDialog] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [deleteProdId, setDeleteProdId] = useState('');
  const [deleteProdName, setDeleteProdName] = useState('');
  const [deleteRow, setDeleteRow] = useState<any>();
  const [snackbarValues, setSnackBarValues] = useState<ISnackBar>({} as ISnackBar);
  const [isPBRDialogOpen, setPBRDialogOpen] = useState<boolean>(false);
  const [apiHitNo, setApiHitNo] = useState<any[]>([]);
  const [paginationModel, setPaginationModel] = useState<{
    page: number;
    pageSize: number;
  }>({
    page: 0,
    pageSize: 20,
  });
  useEffect(() => {
    getPriceBookRules();
    // eslint-disable-next-line
  }, [paginationModel]);

  useEffect(() => {
    getPriceBookRules();
    // eslint-disable-next-line
  }, [selectedPriceBook]);

  useEffect(() => {
    if (refetchData) getPriceBookRules();
    // eslint-disable-next-line
  }, [refetchData]);

  useEffect(() => {
    getPricebooks();
    getDesignations();
    getUsers();
    getOpportunityTypes();
  }, []);

  const getPricebooks = async () => {
    await PriceBookClient.getAllPriceBooks()
      .then(({ data }) => {
        addUniqueApiNames('getPricebooks');
        dispatch(setPricebooks([...data]));
      })
      .catch((e: any) => {
        console.error(e);
      });
  };
  const getDesignations = async () => {
    try {
      const { data } = await PriceBookRuleClient.getDesignations();
      dispatch(setDesignations(data));
      addUniqueApiNames('getDesignations');
    } catch (e) {
      console.error(e);
    }
  };

  const getUsers = async () => {
    await PriceBookRuleClient.getUsers()
      .then(({ data }) => {
        dispatch(setUsers(data));
        addUniqueApiNames('getUsers');
      })
      .catch((e: any) => {
        console.error(e);
      });
  };

  const getOpportunityTypes = async () => {
    await PriceBookRuleClient.getOpportunityTypes()
      .then(({ data }) => {
        dispatch(setOppTypes(data));
        addUniqueApiNames('getOpportunityTypes');
      })
      .catch((e: any) => {
        console.error(e);
      });
  };
  const addUniqueApiNames = (newItem: string) => {
    setApiHitNo((prevItems) => {
      if (!prevItems.includes(newItem)) {
        return [...prevItems, newItem];
      }
      return prevItems;
    });
  };

  const getPriceBookRules = () => {
    setIsDataLoading(true);
    const data = {
      pricebook_id: selectedPriceBook ? selectedPriceBook.id : '',
      offset: paginationModel.page * paginationModel.pageSize,
      limit: paginationModel.pageSize,
    };
    PriceBookRuleClient.getPriceBookRules(data)
      .then((response: any) => {
        dispatch(setRefetchData(false));
        dispatch(setPriceBookRules(response.data));
        dispatch(setTotalPriceBookRules(response.total_items));
        setIsDataLoading(false);
      })
      .catch((e) => {
        setIsDataLoading(false);
        console.error(e);
      });
  };

  const onEditPriceBookRule = (data: any) => {
    dispatch(setEditData(data.row));
    dispatch(setEditMode(true));
    setPBRDialogOpen(true);
  };

  const openDeleteModal = (data: any): any => {
    setDeleteProdId(data.row.id);
    setDeleteProdName(data.row.price_book_name);
    setDeleteRow(data.row);
    setShowDeleteConfirmDialog(true);
  };

  const onDeletePriceBookRule = () => {
    setDeleteLoader(true);

    PriceBookRuleClient.deletePriceBookRule(deleteProdId)
      .then((response: any) => {
        if (response.message === 'success') {
          dispatch(deletePriceBook(deleteRow));
          setDeleteLoader(false);
          dispatch(setRefetchData(true));
          setTimeout(() => {
            setSnackBarValues({
              display: true,
              type: 'success',
              message: intl.formatMessage({ id: 'pricebookRuleDeleted' }),
            });
          }, 0);
          setShowDeleteConfirmDialog(false);
        }
      })
      .catch((e) => {
        setDeleteLoader(false);
        console.error(e);
      });
  };

  const handlePaginationModelChange = (newModel: any) => {
    setPaginationModel(newModel);
  };
  return (
    <>
      <Grid container>
        <Grid item md={12}>
          <Paper
            sx={{
              padding: '18px 33px',
              marginRight: '20px',
            }}>
            <Box
              key={uuidv4()}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}>
              <FormControl sx={{ margin: '20px 4px', width: '280px' }}>
                <SearchAddAutocomplete
                  caption="pricebook"
                  data={priceBooks}
                  selectedItem={selectedPriceBook}
                  setSelectedData={(value: any) => {
                    setSelectedPriceBook(value);
                  }}
                  showAddOption={false}
                  showSelectionValue
                />
              </FormControl>
              {apiHitNo.length === 4 && (
                <CreatePricebookRule
                  isPBRDialogOpen={isPBRDialogOpen}
                  setPBRDialogOpen={setPBRDialogOpen}
                />
              )}
            </Box>
            <br />
            <br />
            <Box key={uuidv4()} sx={{ width: '100%', height: '550px' }}>
              <DataGrid
                rows={isDataLoading || apiHitNo.length !== 4 ? [] : priceBookRules}
                columns={getColumns(onEditPriceBookRule, openDeleteModal)}
                getRowId={() => uuidv4()}
                density="comfortable"
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 20,
                      page: 0,
                    },
                  },
                  sorting: {
                    sortModel: [{ field: 'created_on', sort: 'desc' }],
                  },
                }}
                key={uuidv4()}
                pageSizeOptions={[20, 30]}
                disableRowSelectionOnClick
                sx={commonStyle.dateGridStyle}
                loading={isDataLoading || apiHitNo.length !== 4}
                rowCount={totalPriceBookRules}
                pagination
                paginationMode="server"
                paginationModel={paginationModel}
                onPaginationModelChange={handlePaginationModelChange}
              />
            </Box>
          </Paper>
        </Grid>
      </Grid>
      {showDeleteConfirmDialog ? (
        <ConfirmDialog
          dialogConfig={{
            name: 'confirmDelete',
            text: 'confirmMessageWithName',
            open: showDeleteConfirmDialog,
            productName: deleteProdName,
            loader: deleteLoader,
            handleConfirm: onDeletePriceBookRule,
            handleCancel: () => {
              setDeleteProdId('');
              setDeleteProdName('');
              setShowDeleteConfirmDialog(false);
            },
          }}
        />
      ) : null}
      {snackbarValues.message ? (
        <Snackbar
          display={snackbarValues.display}
          type={snackbarValues.type}
          message={snackbarValues.message}
          onClose={() => setSnackBarValues({ display: false } as ISnackBar)}
        />
      ) : null}
    </>
  );
};
export default injectIntl(PricebookRule);
