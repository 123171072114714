import _ from 'lodash';
import axiosInstance from '../axiosInstance';

export const PATHS = Object.freeze({
  DEAL_DESK: `/api/v1/deal_desk`,
});

interface GetDealsOptions {
  filters?: any;
  offset?: number;
  limit?: number;
  opportunityId?: string;
}

const getDeals = async (options: GetDealsOptions): Promise<any> => {
  const { filters, offset, limit, opportunityId } = options;
  const filterParams: any = [];
  _.keys(filters).forEach((k: string) => {
    if (filters[k]) filterParams.push(`${k}=${filters[k]}`);
  });
  let filterPath = `${PATHS.DEAL_DESK}`;
  if (filterParams.length && limit) {
    filterPath = `${PATHS.DEAL_DESK}?${filterParams.join('&')}&offset=${offset}&limit=${limit}`;
  } else if (filterParams.length && !limit) {
    filterPath = `${PATHS.DEAL_DESK}?${filterParams.join('&')}`;
  } else if (!filterParams.length && limit && !opportunityId) {
    filterPath = `${PATHS.DEAL_DESK}?offset=${offset}&limit=${limit}`;
  } else if (opportunityId && limit) {
    filterPath = `${PATHS.DEAL_DESK}?opportunity_id=${opportunityId}&offset=${offset}&limit=${limit}`;
  }
  const response = await axiosInstance.get(filterPath);
  return response.data;
};

export interface DealDeskAPIClient {
  readonly getDeals: (options: GetDealsOptions) => Promise<any>;
}

const DealDeskClient: DealDeskAPIClient = Object.freeze({
  getDeals,
});

export default DealDeskClient;
