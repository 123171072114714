import React, { ReactElement, useEffect, useState } from 'react';
import './Pricebook.scss';
import { injectIntl } from 'react-intl';
import { Box, Grid, Paper } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import PricebookClient from '../../api/PriceBook/PricebookAPI';
import {
  setPriceBooks,
  setRefetchData,
  setTotalPriceBooks,
} from '../../store/price_book/pricebook.slice';
import ViewPricebooks from './ViewPricebooks';
import CreatePricebook from './CreatePricebook';
import commonStyle from '../../styles/commonStyle';

interface IProps {
  intl?: any;
}

const Pricebook: React.FC<IProps> = (): ReactElement => {
  const dispatch = useDispatch();
  const ability = useSelector((state: any) => state.auth.ability);
  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
  const refetchData = useSelector((state: any) => state.priceBook.refetchData);
  const [paginationModel, setPaginationModel] = useState<{
    page: number;
    pageSize: number;
  }>({
    page: 0,
    pageSize: 20,
  });

  useEffect(() => {
    getPriceBooks();
    // eslint-disable-next-line
  }, [paginationModel]);

  useEffect(() => {
    if (refetchData) getPriceBooks();
    // eslint-disable-next-line
  }, [refetchData]);

  const getPriceBooks = () => {
    setIsDataLoading(true);
    const data = {
      opportunityId: '',
      offset: paginationModel.page * paginationModel.pageSize,
      limit: paginationModel.pageSize,
    };
    PricebookClient.getPriceBooks(data)
      .then((response: any) => {
        dispatch(setRefetchData(false));
        dispatch(setPriceBooks(response.data));
        dispatch(setTotalPriceBooks(response.total_items));
        setIsDataLoading(false);
      })
      .catch((e) => {
        setIsDataLoading(false);
        console.error(e);
      });
  };

  return (
    <Grid sx={commonStyle.bodyContainer} container>
      <Grid item md={12}>
        <Paper
          sx={{
            padding: '18px 33px',
            marginRight: '20px',
          }}>
          <Box
            sx={{
              textAlign: 'right',
            }}>
            {ability.can('POST', 'Price Book') ? <CreatePricebook /> : null}
          </Box>
          <br />
          <ViewPricebooks
            dataLoading={isDataLoading}
            setPaginationModel={setPaginationModel}
            paginationModel={paginationModel}
          />
        </Paper>
      </Grid>
    </Grid>
  );
};

export default injectIntl(Pricebook);
